import React, { useEffect, useState } from "react";
import { NavigationDropdown } from "./menu-dropdown.styles";
import {
  Bars,
  Circle,
  HexagonLeft,
  HexagonRight,
  Triangle,
} from "../figures/figures";
import MenuItem from "../menu-item/menu-item.component.jsx";
import { useSelector } from "react-redux";
import { findIndex, cloneDeep, filter } from "lodash";

import logoSmall from "../../assets/images/menu/logo-small-icon.svg";
import walmartIcon from "../../assets/images/menu/walmart-spark-icon.png";
import amazonIcon from "../../assets/images/menu/amazon-icon.png";
import shopifyIcon from "../../assets/images/menu/shopify-icon.png";
import agencyIcon from "../../assets/images/menu/agency-icon.png";
import wholesaleIcon from "../../assets/images/menu/wholesale-icon.png";
import twoDWorkflowIcon from "../../assets/images/menu/2d-workflow-icon.png";
import inventoryManagementIcon from "../../assets/images/menu/inventory-management-icon.png";
import fbmIcon from "../../assets/images/menu/fbm-icon.png";
import settingsIcon from "../../assets/images/menu/settings-icon.png";
import distribution from "../../assets/images/menu/distribution-icon.png";
import threePLFulfillment from "../../assets/images/menu/3pl-fulfillment-icon.png";
import wmsFeatures from "../../assets/images/menu/wms-features-icon.png";
import returnsAndRemovals from "../../assets/images/menu/return-removals-icon.png";
import threePLTools from "../../assets/images/menu/3pl-tools-icon.png";

const initialMenuContent = [
  {
    icon: agencyIcon,
    title: "Agency",
    tabs: [
      { title: "User Access", link: "/affiliate", reducers: ["affiliate"] },
      {
        title: "Invoice Adjustments",
        link: "/affiliate/profit-calculation-manager/dashboard",
        reducers: ["profitCalculationManagerDashboard"],
      },
      {
        title: "Client Invoices",
        link: "/affiliate/profit-calculation-manager/invoices",
        reducers: ["affiliateInvoices"],
      },
      // {
      //   title: "Settings",
      //   link: "/affiliate/account-settings",
      //   reducers: ["affiliateAccountSettings"],
      // },
    ],
    marketplaces: [walmartIcon, amazonIcon, shopifyIcon],
  },
  // {
  //   icon: distribution,
  //   title: "Distribution (beta)",
  //   tabs: [
  //     {
  //       title: "Create a New Inbound Po",
  //       link: "/combined-orders",
  //       reducers: ["combinedOrders"],
  //     },
  //     {
  //       title: "Active Clients",
  //       link: "/distro-customers",
  //       reducers: ["distroCustomers"],
  //     },
  //     { title: "Payment History", link: "/journal", reducers: ["journal"] },
  //   ],
  //   marketplaces: [amazonIcon],
  // },
  {
    icon: wholesaleIcon,
    title: "Wholesale",
    tabs: [
      {
        title: "My Orders",
        link: '/purchase-order',
        reducers: ['uploadPurchaseOrder', 'simplePurchaseOrders', 'pendingPurchaseOrders']
      },
      {
        title: "Create New FBA inbound Request",
        link: '/purchase-order',
        reducers: ['uploadPurchaseOrder', 'simplePurchaseOrders', 'pendingPurchaseOrders']
      },
      {
        title: "Ai Repricing",
        link: "/reprice/guide?panel=authorization",
        reducers: ["repricerDashboard"],
      },
      {
        title: "Repricer Dashboard",
        link: "/reprice/dashboard",
        reducers: ["Dashboard", "Setting", "Products"],
      },
      { title: "Vendors CRM", link: "/vendors", reducers: ["vendors"] },
      {
        title: "Catalog Analyzer",
        link: "/catalog-jobs",
        reducers: ["catalogJobs", "processedCatalogs"],
      },
      { title: "Sales & Profit Report", link: "/sales", reducers: ["sales"] },
      {
        title: "Inventory Management",
        link: "/products?tab=amazon",
        reducers: ["products", "Products", "pendingPoIndices"],
      },
      // {
      //   title: "PO Management",
      //   link: "/po/dashboard",
      //   reducers: [
      //     // 'uploadPurchaseOrder',
      //     // 'simplePurchaseOrders',
      //     // 'pendingPurchaseOrders',
      //     // 'pendingPoIndices',
      //     // 'allOrders',
      //     // 'poInvoices',
      //     // 'purchasingInvoices',
      //     "dashboard",
      //   ],
      // }
    ],
    marketplaces: [amazonIcon],
  },
  // {
  //   icon: repricer,
  //   title: "Ai Repricer",
  //   tabs: [
  //     {
  //       title: "Dashboard",
  //       link: "/reprice/dashboard",
  //       reducers: ["Dashboard", "Setting", "Products"],
  //     },
  //     {
  //       title: "Products",
  //       link: "/reprice/products",
  //       reducers: ["Strategies", "Products", "Setting"],
  //     },
  //     {
  //       title: "Strategies",
  //       link: "/reprice/strategies",
  //       reducers: ["Strategies", "Products", "Setting"],
  //     },
  //     {
  //       title: "Price History",
  //       link: "/reprice/price-history",
  //       reducers: ["History", "Setting"],
  //     },
  //     {
  //       title: "Settings",
  //       link: "/reprice/settings",
  //       reducers: ["Strategies", "Setting", "Products"],
  //     },
  //     {
  //       title: "Set-up",
  //       link: "/reprice/guide?panel=authorization",
  //       reducers: ["repricerDashboard"],
  //     },
  //   ],
  //   marketplaces: [amazonIcon],
  // },
  {
    icon: twoDWorkflowIcon,
    title: "2D Workflow",
    tabs: [
      // {
      //   title: "Automate Your Inbound Shipments",
      //   link: "/fba-inbound",
      //   reducers: ["fbaInbound"],
      // },
      {
        title: "Automate Your Inbound Shipments",
        link: "/fba-inbound-new",
        reducers: ["fbaInbound"],
      },
      // { title: "Fba Inbound", link: "/fba-inbound-new", reducers: [""] },
      {
        title: "All Shipments",
        link: "/transport-plans",
        reducers: ["shipments"],
      },
    ],
    marketplaces: [amazonIcon],
  },
  // {
  //   icon: threePLFulfillment,
  //   title: "3PL Fulfillment",
  //   tabs: [
  //     { title: "Create New FBA inbound / Storage Request", link: '/purchase-order', reducers: ['uploadPurchaseOrder', 'simplePurchaseOrders', 'pendingPurchaseOrders'] },
  //     // { title: "FBA New Flow", link: '/purchase-order', reducers: ['fbaShipment'] },
  //     // { title: "Create a New Storage Request", link: '/po/new-po-plan', reducers: ['uploadPurchaseOrder', 'simplePurchaseOrders', 'pendingPurchaseOrders'] }
  //   ],
  //   marketplaces: [walmartIcon, amazonIcon, shopifyIcon],
  // },
  // {
  //   icon: inventoryManagementIcon,
  //   title: "Inventory Management",
  //   tabs: [
  //     {
  //       title: "At Amazon/Walmart Inventory",
  //       link: "/products?tab=amazon",
  //       reducers: ["products", "Products", "pendingPoIndices"],
  //     },
  //     {
  //       title: "At 3PL Inventory",
  //       link: "/products?tab=local",
  //       reducers: ["products", "Products", "pendingPoIndices"],
  //     },
  //   ],
  //   marketplaces: [walmartIcon, amazonIcon, shopifyIcon],
  // },
  {
    icon: threePLTools,
    title: "3PL Tools",
    tabs: [
      {
        title: "Order Fulfillment",
        link: "/orders-fulfillment",
        reducers: ["ordersFulfillment"],
      },
      {
        title: "3PL Payments",
        link: "/3pl-payments",
        reducers: ["3plPayments"],
      },
      {
        title: "Manage Storage Locations",
        link: "/home",
        reducers: ["internalStorage"],
      },
      {
        title: "Create New Storage Location",
        link: "/warehouse-location",
        reducers: ["internalStorage"],
      },
    ],
    marketplaces: [walmartIcon, amazonIcon, shopifyIcon],
  },
  // {
  //   icon: wmsFeatures,
  //   title: "WMS Features",
  //   tabs: [
  //     {
  //       title: "Create New Storage Location",
  //       link: "/warehouse-location",
  //       reducers: ["internalStorage"],
  //     },
  //     {
  //       title: "Manage Storage Locations",
  //       link: "/home",
  //       reducers: ["internalStorage"],
  //     },
  //   ],
  //   marketplaces: [logoSmall],
  // },
  {
    icon: fbmIcon,
    title: "FBM",
    tabs: [
      { title: "Automatic Lister", link: "/fbm/lister", reducers: [] },
      {
        title: "Fulfill Open Orders",
        link: "/fbm/orders/fulfillment",
        reducers: [],
      },
      { title: "Manage All Orders", link: "/fbm/orders", reducers: [] },
      { title: "Marketplace Settings", link: "/fbm/settings", reducers: [] },
      { title: "Shipping Fee Wallet", link: "/fbm/wallet", reducers: [] },
      { title: "Dashboard", link: "/fbm", reducers: [] },
    ],
    marketplaces: [walmartIcon, amazonIcon, shopifyIcon],
  },
  // {
  //   icon: returnsAndRemovals,
  //   title: "Returns & Removals",
  //   tabs: [
  //     {
  //       title: "Search Removal",
  //       link: "/fba-removal-shipments/search",
  //       reducers: ["fbaremovalshipments"],
  //     },
  //     {
  //       title: "Setup Automatic Removal Claims",
  //       link: "/user-removals",
  //       reducers: ["userRemovals"],
  //     },
  //     {
  //       title: "All Removals",
  //       link: "/received-removal-orders",
  //       reducers: ["masterRemovalOrders"],
  //     },
  //   ],
  //   marketplaces: [amazonIcon, walmartIcon],
  // },
  {
    icon: settingsIcon,
    title: "Settings",
    tabs: [
      { title: "Account Integration", link: "/account", reducers: ["account"] },
      {
        title: "Payment Info",
        link: "/payments",
        reducers: ["payment", "affiliateAccountSettings"],
      },
      { title: "Users", link: "/users", reducers: ["workers"] },

      // { title: "Subscription Invoice", link: '/subscription-invoices', reducers: ['subscriptionInvoices'] },
      // { title: "Two Steps [Master] Invoice", link: '/two-step-master-invoices', reducers: ['twoStepInvoices'] },
      // { title: "Two Steps Invoice", link: '/two-step-invoices', reducers: ['twoStepInvoices'] },
    ],
    marketplaces: [logoSmall],
  },
];

const MenuDropDown = ({ isDropdownShown, setIsDropdownShown }) => {
  const [navLinksContent, setNavLinksContent] = useState(initialMenuContent);

  const { currentUser: user } = useSelector((state) => state?.auth || {});

  const isOwner = user?.email == "steven@wraithco.com";
  const isAdmin = user?.role_id == 1 || user?.role_id == 3;
  const isWarehouseWorker = user?.warehouse_manager_id;
  const isWorker = user?.role_id == 5;
  const isNotSpecialUser = user?.email != "test@sales.support.com";

  const {
    is_public_warehouse,
    is_affiliate_manager_worker,
    is_public_warehouse_worker,
    is_affiliate_manager,
  } = user || {};
  const allowMobileScreens =
    is_public_warehouse ||
    is_public_warehouse_worker ||
    is_affiliate_manager ||
    is_affiliate_manager_worker;
  const allowRemovalPages = is_public_warehouse || is_affiliate_manager;

  useEffect(() => {
    const navLinksContentClone = cloneDeep(initialMenuContent);

    // if (!user?.is_affiliate_manager && !isOwner) {
    //   const index = findIndex(navLinksContentClone, { title: "Agency" });
    //   navLinksContentClone.splice(index, 1);
    // }
    // else {
    //   if (!user?.master_shipment_enabled) {
    //     const index = findIndex(navLinksContentClone, { title: "Agency" });
    //     const tab_index = findIndex(navLinksContentClone[index].tabs, { title: "Settings" });
    //     navLinksContentClone[index].tabs.splice(tab_index, 1);
    //   }
    // }

    // if (!["mikeakrel@gmail.com", "makapakashopify@gmail.com", "steven@dailydistro.com"].includes(user?.email)) {
    //   const index = findIndex(navLinksContentClone, { title: "Distribution (beta)" });
    //   navLinksContentClone.splice(index, 1);
    // }

    // if (isWarehouseWorker || isWorker) {
    //   const whole_sale_index = findIndex(navLinksContentClone, { title: "Wholesale" });
    //   const filteredTabs = filter(navLinksContentClone[whole_sale_index].tabs, (obj) => (['Vendors CRM', 'Sales & Profit Report'].includes(obj.title)))
    //   navLinksContentClone[whole_sale_index].tabs = filteredTabs;

    //   const repricer_index = findIndex(navLinksContentClone, { title: "Ai Repricer" });
    //   navLinksContentClone.splice(repricer_index, 1);
    // }
    // else {
    //   const repricer_index = findIndex(navLinksContentClone, { title: "Ai Repricer" });
    //   const repricer_tabs = [];

    //   if (isOwner) {
    //     repricer_tabs.push(
    //       { title: "Dashboard", link: '/reprice/dashboard', reducers: ['Dashboard', 'Setting', 'Products'] }
    //     );
    //   }
    //   else if (user?.is_listing_synced) {
    //     repricer_tabs.push(
    //       { title: "Dashboard", link: '/reprice/dashboard', reducers: ['Dashboard', 'Setting', 'Products'] },
    //       { title: "Products", link: '/reprice/products', reducers: ['Strategies', 'Products', 'Setting'] },
    //       { title: "Strategies", link: '/reprice/strategies', reducers: ['Strategies', 'Products', 'Setting'] },
    //       { title: "Price History", link: '/reprice/price-history', reducers: ['History', 'Setting'] },
    //       { title: "Settings", link: '/reprice/settings', reducers: ['Strategies', 'Setting', 'Products'] },
    //       { title: "Guide", link: '/reprice/guide?panel=authorization', reducers: ['repricerDashboard'] },
    //     );
    //   }
    //   else {
    //     repricer_tabs.push(
    //       { title: "Setup Ai Repricing", link: '/reprice/guide?panel=authorization', reducers: ['repricerDashboard'] }
    //     );
    //   }

    //   navLinksContentClone[repricer_index].tabs.push(...repricer_tabs);
    // }

    // if (!user?.is_owner_warehouse && !user?.email == "mikeakrel@gmail.com") {
    //   const index = findIndex(navLinksContentClone, { title: "3PL Tools" });
    //   navLinksContentClone.splice(index, 1);
    // }

    // if (!allowMobileScreens) {
    //   const index = findIndex(navLinksContentClone, { title: "WMS Features" });
    //   navLinksContentClone.splice(index, 1);
    // }

    if (!isWorker && !isWarehouseWorker) {
      const settings_index = findIndex(navLinksContentClone, {
        title: "Settings",
      });
      const setting_tabs = cloneDeep(navLinksContentClone[settings_index].tabs);

      if (isAdmin || user?.master_shipment_enabled) {
        setting_tabs.push({
          title: "Shipment [Master] Invoice",
          link: "/invoices",
          reducers: ["customerInvoices", "affiliateAccountSettings"],
        });
      }

      if (isAdmin) {
        setting_tabs.push({
          title: "Removals [Master] Invoice",
          link: "/master-removal-invoices",
          reducers: ["masterRemovalInvoices"],
        });
      }

      if (user?.is_warehouse_owner_admin) {
        setting_tabs.push({
          title: "Storage [Master] Invoice",
          link: "/master-storage-invoices",
          reducers: ["masterStorageInvoices"],
        });
      }

      setting_tabs.push(
        {
          title: "Shipment Invoice",
          link: "/user-invoices",
          reducers: ["userInvoices", "affiliateAccountSettings"],
        },
        {
          title: "Removals Invoice",
          link: "/removal-invoices",
          reducers: ["removalInvoices"],
        },
        {
          title: "Storage Invoice",
          link: "/storage-invoices",
          reducers: ["userStorageInvoices"],
        },
        { title: "Settings Invoice", link: "/invoices/settings", reducers: [] }
      );

      navLinksContentClone[settings_index].tabs = setting_tabs;
      setNavLinksContent(navLinksContentClone);
    }
  }, []);

  return (
    <NavigationDropdown
      key="navigation-dropdown"
      initial={{ y: -250, opacity: 0 }}
      transition={{ type: "spring", stiffness: 100 }}
      animate={isDropdownShown ? { y: 0, opacity: 1 } : { y: -250, opacity: 0 }}
      exit={{ y: -250, opacity: 0, transition: { duration: 0.5 } }}
    >
      {navLinksContent.map((obj, index) => (
        <MenuItem
          key={`item-${index}`}
          obj={obj}
          setIsDropdownShown={setIsDropdownShown}
        />
      ))}

      {/* <HexagonLeft />
      <Triangle />
      <HexagonRight />
      <Bars />
      <Circle /> */}
    </NavigationDropdown>
  );
};

export default MenuDropDown;
