import axios from '../../../axios_config';
import { findIndex } from 'lodash';
import { notification } from 'antd';

export const sendToPO = (id) => (dispatch) => {
  dispatch({ type: 'PLACE_ORDER_FOR_FBA_REMOVAL_SHIPMENT_DETAILS_REQUEST' });

  return axios({
    method: 'post',
    url: `/api/v2/fba_removal_shipments/${id}/add_to_po`
  }).then(({ data }) => {
    const { success, message } = data;
    if (success) {
      notification.success({
        message: 'Place Order',
        description: message,
        top: 65
      });
      return dispatch({ type: 'PLACE_ORDER_FOR_FBA_REMOVAL_SHIPMENT_DETAILS_SUCCESS' });
    }

    notification.warning({
      message: 'Place Order',
      description: message,
      top: 65
    });
    return dispatch({ type: 'PLACE_ORDER_FOR_FBA_REMOVAL_SHIPMENT_DETAILS_FAILED' });
  }).catch((error) => {
    if (axios.isCancel(error)) return;

    notification.error({
      message: 'Place Order',
      description: error.message,
      top: 65
    });

    return dispatch({ type: 'PLACE_ORDER_FOR_FBA_REMOVAL_SHIPMENT_DETAILS_FAILED' });
  });
};

export const getSkusForTrackingID = (trackingID) => (dispatch) => {
  dispatch({ type: 'GET_FBA_REMOVAL_SHIPMENT_DETAILS_REQUEST' });

  return axios({
    method: 'get',
    url: `/api/v2/fba_removal_shipments/by_tracking_id/${trackingID}`
  }).then(({ data }) => {
    return dispatch({ type: 'GET_FBA_REMOVAL_SHIPMENT_DETAILS_SUCCESS', payload: data });
  }).catch((error) => {
    if (axios.isCancel(error)) return;

    notification.error({
      top: 80,
      message: 'Error in getSkusForTrackingID'
    });
    return dispatch({ type: 'GET_FBA_REMOVAL_SHIPMENT_DETAILS_FAILED' });
  });
};

export const getLocationsForUser = (userId) => (dispatch) => {
  dispatch({ type: 'GET_USER_LOCATIONS_REQUEST' });

  return axios({
    method: 'get',
    url: '/api/v2/locations/get',
    params: {
      user_id: userId
    }
  })
    .then(({ data }) => {
      dispatch({
        type: 'GET_USER_LOCATIONS_SUCCESS',
        payload: data
      });

      return { locations: data };
    })
    .catch((error) => {
      if (axios.isCancel(error)) return;

      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};

      notification.error({
        message: 'GET USER LOCATIONS',
        top: 80,
        description: message || error.message
      });

      dispatch({ type: 'GET_USER_LOCATIONS_FAILED' });

      return { error };
    });
};

export const clearUserLocations = () => (dispatch) => {
  dispatch({ type: 'CLEAR_USER_LOCATIONS' });
};

export const saveItemForStorage = ({
  rowNumberForStorage,
  palletNumberForStorage,
  sellableQuantity,
  unSellableQuantity,
  userID,
  vendorItemId,
  removalId
}) => (dispatch) => {
  dispatch({ type: 'DETAILS_SAVE_ITEM_FOR_STORAGE_REQUEST' });
  let locationItems = [];
  // eslint-disable-next-line max-len
  if (vendorItemId) {
    locationItems = [{
      storeditem_id: vendorItemId,
      sellable_quantity: sellableQuantity,
      unsellable_quantity: unSellableQuantity
    }];
  }

  return axios({
    method: 'post',
    url: '/api/v2/locations',
    params: {
      location: {
        fba_removal_shipment_id: removalId,
        pallet: palletNumberForStorage,
        row: rowNumberForStorage,
        user_id: userID,
        location_items: locationItems
      }
    }
  }).then(({ data }) => {
    const { success, message } = data;
    if (success) {
      return dispatch({ type: 'DETAILS_SAVE_ITEM_FOR_STORAGE_SUCCESS', success });
    }
    notification.warning({
      top: 80,
      message
    });
    return dispatch({ type: 'DETAILS_SAVE_ITEM_FOR_STORAGE_FAILED', success });
  }).catch((error) => {
    if (axios.isCancel(error)) return;

    const { response, message } = error || {};
    const { data } = response || {};
    notification.error({
      top: 80,
      message: (data && data.message) ? data.message : message
    });
    return dispatch({ type: 'DETAILS_SAVE_ITEM_FOR_STORAGE_FAILED', success: false });
  });
};

export const addIntoInternalStorage = ({
  identity,
  sellable,
  unsellable,
  user,
  vendor,
  location,
  fba_removal_shipment_id
}) => (dispatch) => {
  dispatch({ type: 'DETAILS_SAVE_ITEM_FOR_STORAGE_REQUEST' });

  return axios({
    method: 'POST',
    url: `/api/v2/locations/${location}/add_items_to_storage`,
    data: {
      user_id: user,
      vendor_id: vendor,
      fba_removal_shipment_id,
      location_items: [{
        item_status: 'General',
        sellable_quantity: sellable,
        unsellable_quantity: unsellable,
        item_ref: identity
      }]
    }
  })
    .then(() => {
      notification.success({
        message: 'SAVE STORAGE',
        description: 'Item has been added successfully into internal storage',
        top: 65
      });

      dispatch({ type: 'DETAILS_SAVE_ITEM_FOR_STORAGE_SUCCESS' });
      return { isAdded: true };
    })
    .catch((error) => {
      if (axios.isCancel(error)) return;

      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};

      notification.error({
        message: 'SAVE STORAGE',
        description: message || error.message,
        top: 65
      });
      dispatch({ type: 'DETAILS_SAVE_ITEM_FOR_STORAGE_FAILED' });
      return { error };
    });
};

export const addIntoStagingItems = (data) => (dispatch) => {
  dispatch({ type: 'DETAILS_SAVE_ITEM_FOR_STORAGE_REQUEST' });

  return axios({
    method: 'POST',
    url: `/api/v2/staging_items`,
    data
  })
    .then(({ data }) => {
      notification.success({
        message: 'SAVE STORAGE',
        description: 'Item has been added successfully into internal storage',
        top: 65
      });

      dispatch({ type: 'DETAILS_SAVE_ITEM_FOR_STORAGE_SUCCESS' });
      return { data };
    })
    .catch((error) => {
      if (axios.isCancel(error)) return;

      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};

      notification.error({
        message: 'SAVE STORAGE',
        description: message || error.message,
        top: 65
      });
      dispatch({ type: 'DETAILS_SAVE_ITEM_FOR_STORAGE_FAILED' });
      return { error };
    });
};

export const disposeWarehouseAction = (id) => (dispatch) => {
  dispatch({ type: 'DISPOSE_ITEM_REQUEST' });

  return axios({
    method: 'POST',
    url: `/api/v2/fba_removal_shipments/${id}/dispose_warehouse_action`
  })
    .then(() => {
      notification.success({
        message: 'DISPOSE ITEM',
        description: 'Item has been disposed successfully',
        top: 65
      });

      dispatch({ type: 'DISPOSE_ITEM_SUCCESS' });
      return { isDisposed: true };
    })
    .catch((error) => {
      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};

      notification.error({
        message: 'DISPOSE ITEM',
        description: message || error.message,
        top: 65
      });
      dispatch({ type: 'DISPOSE_ITEM_FAILED' });
      return { error };
    });
};

export const saveShipmentForRemoval = ({
  id,
  received_quantity,
  sellable_quantity,
  unsellable_quantity,
  user_note,
  uploadedImages
}) => (dispatch) => {
  dispatch({ type: 'UPDATE_SHIPMENT_FOR_REMOVAL_REQUEST' });
  return axios({
    method: 'put',
    url: `/api/v2/fba_removal_shipments/${id}`,
    params: {
      fba_removal_shipment: {
        received_quantity,
        sellable_quantity,
        unsellable_quantity,
        user_note,
        rs_images_attributes: uploadedImages
      }
    }
  }).then(({ data }) => {
    const { success } = data;
    if (success) {
      notification.success({
        top: 80,
        message: 'Successfully updated!'
      });
      return dispatch({ type: 'UPDATE_SHIPMENT_FOR_REMOVAL_SUCCESS' });
    }
    notification.warning({
      top: 80,
      message: 'Update not successful!'
    });
    return dispatch({ type: 'UPDATE_SHIPMENT_FOR_REMOVAL_FAILED' });
  }).catch((error) => {
    if (axios.isCancel(error)) return;

    notification.error({
      top: 80,
      message: 'Error in Updating shipment'
    });
    return dispatch({ type: 'UPDATE_SHIPMENT_FOR_REMOVAL_FAILED' });
  });
};

export const submitAddToWorkOrder = ({
  id,
  isLooseQty,
  looseQty,
  boxQty,
  boxInQty,
  bundleQuantity
}) => (dispatch) => {
  dispatch({ type: 'ADD_TO_WORK_ORDER_FOR_SHIMENT_REMOVAL_REQUEST' });

  return axios({
    method: 'POST',
    url: `/api/v2/fba_removal_shipments/${id}/generate_work_order`,
    data: {
      quantity: looseQty,
      bundle_quantity: bundleQuantity,
      box_qty: boxQty,
      case_qty: boxInQty,
      loose_box: isLooseQty
    }
  })
    .then(({ data }) => {
      const { fba_removal_shipment: removal, message } = data || {};
      dispatch({ type: 'ADD_TO_WORK_ORDER_FOR_SHIMENT_REMOVAL_SUCCESS' });

      notification.success({
        message: 'ADD TO PO',
        description: message,
        top: 65
      });

      return { removal };
    })
    .catch((error) => {
      if (axios.isCancel(error)) return;

      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};

      notification.error({
        message: 'ADD TO PO',
        description: message || error.message,
        top: 65
      });

      dispatch({ type: 'ADD_TO_WORK_ORDER_FOR_SHIMENT_REMOVAL_FAILED' });
      return { error }
    });
};

export const getDimensions = ({ asin }) => (dispatch) => {
  dispatch({ type: 'FETCH_DIMENSIONS_REQUEST' });

  return axios({
    method: 'GET',
    url: `/api/v2/vendor_asins/dimensions?asin=${asin}`
  })
    .then(({ data }) => {
      const { success, dimensions } = data || {};
      if (success) return dispatch({ type: 'FETCH_DIMENSIONS_SUCCESS', payload: { dimensions } });

      notification.error({
        message: 'FETCH DIMENSIONS',
        description: 'Failed To Fetch Dimensions',
        top: 65
      });
      return dispatch({ type: 'FETCH_DIMENSIONS_FAILED' });
    })
    .catch((error) => {
      if (axios.isCancel(error)) return;

      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};

      notification.error({
        message: 'FETCH DIMENSIONS',
        description: message || error.message,
        top: 65
      });
      return dispatch({ type: 'FETCH_DIMENSIONS_FAILED' });
    });
};

export const getAllLocations = () => (dispatch) => {
  dispatch({ type: 'FETCH_LOCATIONS_REQUEST' });

  return axios({
    method: 'GET',
    url: `/api/v2/storages/dropdown`
  })
    .then(({ data }) => {
      const { success, locations } = data || {};
      if (success) return dispatch({ type: 'FETCH_LOCATIONS_SUCCESS', payload: { locations, success } });

      notification.error({
        message: 'FETCH LOCATIONS',
        description: 'Failed To Fetch Locations',
        top: 65
      });
      return dispatch({ type: 'FETCH_LOCATIONS_FAILED' });
    })
    .catch((error) => {
      if (axios.isCancel(error)) return;

      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};

      notification.error({
        message: 'FETCH LOCATIONS',
        description: message || error.message,
        top: 65
      });
      return dispatch({ type: 'FETCH_LOCATIONS_FAILED' });
    });
};

export const createNewLocation = (data) => (dispatch) => {
  dispatch({ type: 'CREATE_NEW_LOCATION_REQUEST' });

  return axios({
    method: 'POST',
    url: `/api/v2/storages`,
    data
  })
    .then(({ data }) => {
      const { success, location } = data || {};
      if (success) return dispatch({ type: 'CREATE_NEW_LOCATION_SUCCESS', payload: { location, success } });

      notification.error({
        message: 'CREATE NEW LOCATION',
        description: 'Failed To Create New Location',
        top: 65
      });
      return dispatch({ type: 'CREATE_NEW_LOCATION_FAILED' });
    })
    .catch((error) => {
      if (axios.isCancel(error)) return;

      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};

      notification.error({
        message: 'CREATE NEW LOCATION',
        description: message || error.message,
        top: 65
      });
      return dispatch({ type: 'CREATE_NEW_LOCATION_FAILED' });
    });
};
