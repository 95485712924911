import React, { lazy } from 'react';

const WorkersContainer = lazy(() => import('../../containers/WorkersContainer'));
const PaymentContainer = lazy(() => import('../../containers/PaymentContainer'));

export const SETTINGS_ROUTES = [
  {
    path: '/users',
    component: <WorkersContainer />,
    group: 'Settings',
    reducer: 'workers'
  },
  {
    path: '/payments',
    component: <PaymentContainer />,
    group: 'Settings',
    reducer: ['payment', 'affiliateAccountSettings']
  },
];
