import React, { Suspense, lazy, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import '../src/assets/style.css';
import '../src/assets/images/no-image.png'
import { ThemeProvider } from 'styled-components';
import config from '../src/config/config';
import axios, { awsAxios } from '../axios_config';
import { useSelector, useDispatch } from 'react-redux';
import { AUTH_ROUTES } from './routes/AuthRoute';
import { ONBOARDING_ROUTES } from './routes/PostSignUpRoute';
import { PRIVATE_APP_ROUTES } from './routes/AppRouteContainer';
import { message, notification } from "antd";
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import LandingPage from './components/indexPage/index';
import ThreePl from './components/threepl/index';
import Terms from './components/terms/index';
import { GetCurrentUser } from './slices/auth';
import { AffiliateManagerInviteAccept } from './slices/account';
import { GetMarketPlacesSettingDetails } from "./slices/fbm";
import moment from 'moment';
const RepricerLandingPage = lazy(() => import('./components/reprice/components/landing-page/landing-page.component.jsx'));
const RepricerRegistration = lazy(() => import('./components/reprice/components/landing-page/components/registration/registration.component.jsx'));
import handleRouteValidations from './utils/routeValidations';
import 'ag-grid-enterprise';

const Layout = lazy(() => import("./components/layout/layout.jsx"));
const { theme } = config;

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isUserFetched, setIsUserFetched] = useState(false);
  const {
    token,
    currentUser: user,
    fetchingCurrentUser,
    impersonatingUser,
    signOutLoading,
  } = useSelector((state) => state.auth || {});
  const { loading: fbmLoading, countsLoading } = useSelector(
    (state) => state.fbm || {}
  );
  const { loading: warehouseLoading } = useSelector(
    (state) => state.warehouse || {}
  );
  const { loading: listerLoading } = useSelector((state) => state.lister || {});
  const {
    qb_subscription_id,
    qb_accounts,
    role,
    confirmed_at,
    stores_count,
    trial_start_at,
    email,
    walmart_consumer_key,
    walmart_secret_key,
    refresh_token,
    auto_ordering_auth_token,
    shopify_access_token,
    platform,
  } = user || {};
  const { settings } = useSelector((state) => state?.fbm || {});
  const { amazonSettings, walmartSettings, shopifySettings } = settings || {};

  const validQbAccount = qb_accounts?.find((qb) => !isEmpty(qb.source_id));

  const { search } = window.location || {};
  const query = new URLSearchParams(search);
  const { id } = useParams();

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      dispatch(GetCurrentUser()).then(() => {
        setIsUserFetched(true);
      });
    }
  }, [token]);

  const isOwner = user?.email == "steven@wraithco.com";
  const isOwnerWorker = user?.warehouse_manager_id == 5;
  const isAdmin = user?.role_id == 1 || user?.role_id == 3;
  const isWarehouseWorker = user?.warehouse_manager_id;
  const isBuyer = user?.role_id == 2;
  const isWorker = user?.role_id == 5;
  const isNotSpecialUser = user?.email != "test@sales.support.com";
  const isAffiliatManager = user?.is_affiliate_manager;
  const specialUser =
    role?.name === "Warehouse" ||
    isOwner ||
    isAffiliatManager ||
    isWarehouseWorker;
  const { is_public_warehouse, is_affiliate_manager } = user || {};
  const allowRemovalPages = is_public_warehouse || is_affiliate_manager;

  useEffect(() => {
    if (fetchingCurrentUser) return;

    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    const currentPath = window?.location?.pathname;
    const reducers = ["catalogJobs", "processedCatalogs"];
    if (
      [
        "/fbm",
        "/fbm/orders",
        "/fbm/orders/fulfillment",
        "/fbm/wallet",
        "/fbm/lister",
      ].includes(currentPath) &&
      isEmpty(amazonSettings) &&
      isEmpty(walmartSettings) &&
      isEmpty(shopifySettings)
    ) {
      dispatch(GetMarketPlacesSettingDetails({ email }));
    }

    let currentGroup = [
      ...AUTH_ROUTES,
      ...ONBOARDING_ROUTES,
      ...PRIVATE_APP_ROUTES,
    ].find(({ path }) => path === currentPath)?.group;

    const landingPaths = [
      "/",
      "/terms",
      "/contact-us",
      "/threepl",
      "/reprice",
      "/reprice/registration",
    ];

    const openOnboardingPath = currentPath.includes('auth/selling-partner');
    const attachAffiliateManagerPath = currentPath.includes('affiliates-attach');

    if (landingPaths.includes(currentPath)) return;

    // Fix group for routes have dynamic values E.g pos/100. it returning undefined.

    if (currentGroup === "Auth") {
      if (!isEmpty(token) && !isEmpty(user)) {
        if (!confirmed_at) {
          return navigate("/auth/email_verification", { replace: true });
        }

        if (specialUser) {
          dispatch({ type: "RESET_APP", reducers });
          navigate("/reprice/dashboard", { replace: true });
        } else if (stores_count === 0) {
          if (platform === "Repricer") {
            navigate(getRepricerRoute(), { replace: true });
          } else {
            navigate("/onboarding/store-add", { replace: true });
          }
        } else if (!qb_subscription_id && !validQbAccount) {
          navigate("/onboarding/select-plan", { replace: true });
        } else {
          dispatch({ type: "RESET_APP", reducers });
          navigate("/catalog-jobs", { replace: true });
        }
      }
    } else if (currentGroup === 'OnBoarding') {
      if (isEmpty(token)) {
        navigate("/auth/sign-in", { replace: true });
      } else {
        if (
          !openOnboardingPath && !(localStorage.getItem('back-to-stores')) &&
          (specialUser ||
            (stores_count > 0 && qb_subscription_id))
        ) {
          dispatch({ type: "RESET_APP", reducers });
          navigate("/catalog-jobs", { replace: true });
        }
      }
    } else if (currentGroup) {
      if (!isEmpty(token) && !isEmpty(user)) {
        if (!confirmed_at) {
          return navigate("/auth/email_verification", { replace: true });
        }

        if (role?.name !== 'Worker' && currentPath === '/referal-users') {
          return navigate('/referal-users', { replace: true });
        } else if (role?.name === 'Worker' && currentPath === '/referal-users') {
          dispatch({ type: 'RESET_APP', reducers });
          return navigate('/catalog-jobs', { replace: true });
        }

        if (!specialUser && stores_count === 0) {
          if (platform === "Repricer") {
            if (window.location.pathname === '/payments') navigate('/payments', { replace: true });
            else navigate(getRepricerRoute(), { replace: true });
          } else {
            navigate("/onboarding/store-add", { replace: true });
          }
        } else if (!specialUser && !qb_subscription_id && !validQbAccount) {
          navigate("/onboarding/select-plan", { replace: true });
        } else {
          const data = { user, settings };
          const { success, message, redirectTo, reducers } =
            handleRouteValidations(data, currentPath);
          if (!success) {
            notification.info({
              message,
              top: 65,
            });
            dispatch({ type: "RESET_APP", reducers });
            navigate(redirectTo, { replace: true });
          }
        }
      } else {
        navigate("/auth/sign-in", { replace: true });
      }
    } else {
      if (isEmpty(token)) {
        navigate("/auth/sign-in", { replace: true });
      }
      else if (attachAffiliateManagerPath) {
        dispatch(AffiliateManagerInviteAccept(currentPath?.replace('affiliates-attach/', '')));
        dispatch({ type: 'RESET_APP', reducers });
        navigate('/catalog-jobs', { replace: true });
      }
    }

    // } else {
    // notification.error({
    //   message: "Requested page doesn't exist !",
    //   top: 65,
    // })
    // navigate('/catalog-jobs', { replace: true });
    // }
  }, [
    window.location.pathname, user, amazonSettings, walmartSettings, shopifySettings,
  ]);

  const getRepricerRoute = () => {
    let repricerRoute = `/reprice/guide?panel=authorization`;

    const authCode = query.get("spapi_oauth_code");
    const authToken = query.get("mws_auth_token");
    const sellerId = query.get("selling_partner_id");

    if (sellerId) {
      repricerRoute += `&spapi_oauth_code=${authCode}`;
      repricerRoute += `&mws_auth_token=${authToken}`;
      repricerRoute += `&selling_partner_id=${sellerId}`;
    }

    return repricerRoute;
  };

  const isLoading =
    impersonatingUser ||
    fetchingCurrentUser ||
    signOutLoading ||
    fbmLoading ||
    countsLoading ||
    warehouseLoading ||
    listerLoading ||
    (token && !isUserFetched);
  const avoidRenderComponent = fetchingCurrentUser || (token && !isUserFetched);
  return (
    <div className='mainApp'>
      <ThemeProvider theme={{ ...theme }} style={{ height: '100%' }}>
        <Suspense style={{ width: '100vw', height: '100vh' }} fallback={<Spin style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} tip='Loading...'></Spin>}>
          <Spin tip='Loading...' spinning={isLoading ? true : false}>
            {avoidRenderComponent
              ? (<div style={{ width: '100vw', height: '100vh' }}></div>)
              : (<Routes>
                <Route
                  path='/'
                  key='/'
                  end
                  element={<LandingPage />}
                />
                <Route
                  path="/threepl"
                  key="/threepl"
                  end
                  element={<ThreePl />}
                />
                <Route path="/terms" key="/terms" end element={<Terms />} />
                <Route
                  path="/reprice"
                  key="/reprice"
                  end
                  element={<RepricerLandingPage />}
                />
                <Route
                  path="/reprice/registration"
                  key="/reprice/registration"
                  end
                  element={<RepricerRegistration />}
                />
                {AUTH_ROUTES.map(({ path, component: Component }) => (
                  <Route path={path} key={path} end element={<Component />} />
                ))}
                {ONBOARDING_ROUTES.map(({ path, component: Component }) => (
                  <Route path={path} key={path} end element={<Component />} />
                ))}
                {PRIVATE_APP_ROUTES.map(({ path, component: Component }) => (
                  <Route
                    path={path}
                    key={path}
                    end
                    element={<Layout currentComponent={Component} />}
                  />
                ))}
                {[
                  "/api/v2/affiliate_quickbooks_auth",
                  "/api/v2/distro_quickbooks_auth",
                  "/api/v2/quickbooks_auth",
                ].map((currentURL) => (
                  <Route
                    path={currentURL}
                    key={currentURL}
                    element={
                      <RedirectToBackend
                        currentURL={currentURL}
                        navigate={() =>
                          navigate("/affiliate/account-settings", {
                            replace: true,
                          })
                        }
                      />
                    }
                  />
                ))}
                <Route
                  path="*"
                  element={<Navigate from="*" to="/catalog-jobs" />}
                />
              </Routes>
            )}
          </Spin>
        </Suspense>
      </ThemeProvider>
    </div>
  );
}

const RedirectToBackend = ({ currentURL, navigate }) => {
  useEffect(() => {
    redirectAPI(currentURL).then(() => navigate());
  }, []);

  const redirectAPI = (currentURL) => {
    return axios({
      method: "GET",
      url: `${process.env.API_BASE_URL}${currentURL}${window.location.search}`,
    })
      .then(({ data }) => {
        const { success, message } = data || {};
        if (success) {
          notification.success({
            message: "Authorization",
            description: message,
            top: 65,
          });
        } else {
          notification.error({
            message: "Authorization",
            description: message,
            top: 65,
          });
        }
      })
      .catch((error) => {
        const { response } = error || {};
        const { data } = response || {};
        const { message } = data || {};

        notification.error({
          message: "Authorization",
          description: message,
          top: 65,
        });
      });
  };

  return (
    <Spin
      tip="Redirecting..."
      spinning={true}
      style={{ marginTop: 100 }}
    ></Spin>
  );
};

export default App;
