import React, { Fragment } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ItemContainer,
  ItemTextWrapper,
  Marketplaces,
  MenuItemText,
  PointText,
  Points,
  TextWithIcon,
} from "./menu-item.styles";
import handleRouteValidations from '../../utils/routeValidations';
import { notification } from 'antd';

const MenuItem = ({ obj, setIsDropdownShown }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { settings } = useSelector((state) => state?.fbm || {});
  const { currentUser: user } = useSelector((state) => state.auth || {});

  const resetAndNavigate = async (link, reducers) => {
    if (window.location.pathname !== link) {
      const data = { settings, user };
      const { success, message, redirectTo } = handleRouteValidations(data, link?.split('?')?.[0]);
      if (!success) {
        if (redirectTo === window.location.pathname) {
          notification.info({
            message,
            top: 65,
          });
          // else the notification will pop-up from App.js
        }
        if (redirectTo !== '/catalog-jobs' && redirectTo !== window.location.pathname) {
          dispatch({ type: 'RESET_APP', reducers });
          navigate(link);
          setIsDropdownShown(false);
        }
        else if (redirectTo === '/catalog-jobs' && redirectTo !== window.location.pathname) {
          notification.info({
            message,
            top: 65,
          });
        }
      }
      else {
        dispatch({ type: 'RESET_APP', reducers });
        navigate(link);
        setIsDropdownShown(false);
      }
    }
  };

  const { pathname, search } = window.location || {};

  return (
    <Fragment>
      <ItemContainer>
        <ItemTextWrapper>
          <TextWithIcon>
            <img src={obj.icon} alt={obj.title} style={{ height: "24px" }} />
            <MenuItemText>{obj.title}</MenuItemText>
          </TextWithIcon>

          <Marketplaces>
            {obj.marketplaces.map((marketplace, index) => {
              return <img src={marketplace} key={`tab_image_${index}`} alt={marketplace} style={{ height: "16px" }} />;
            })}
          </Marketplaces>
        </ItemTextWrapper>

        <Points>
          {obj.tabs.map((tab, index) => {
            return (
              <PointText
                className={`${pathname}${search}` === tab.link ? 'active' : ''}
                key={`tab-${index}`}
                onClick={() => resetAndNavigate(tab.link, tab.reducers)}
              >
                {tab.title}
              </PointText>
            );
          })}
        </Points>
      </ItemContainer>
    </Fragment>
  );
};

export default MenuItem;
