import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
};


const Settings = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'DISABLE_REPRICER_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'DISABLE_REPRICER_SUCCESS': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'DISABLE_REPRICER_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'ENABLE_REPRICER_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'ENABLE_REPRICER_SUCCESS': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'ENABLE_REPRICER_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_REPRICER_MODE_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'SET_REPRICER_MODE_SUCCESS': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_REPRICER_MODE_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }

    default:
      return state;
  }
};

export default Settings;
