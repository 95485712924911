import React, { lazy } from 'react';
import { useParams } from "react-router-dom";
import DashboardContainer from '../components/purchaseOrders/combinedPoAndDashboard.jsx';

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

import { AFFILIATE_ROUTES } from './privateAppRoutes/AffiliateRoutes';
import { CATALOG_ROUTES } from './privateAppRoutes/CatalogRoutes';
import { DISTRO_ROUTES } from './privateAppRoutes/DistroRoutes';
import { FBA_INBOUND_ROUTES } from './privateAppRoutes/FbaInboundRoutes';
import { FBM_ROUTES } from './privateAppRoutes/FbmRoutes';
import { INVOICES_ROUTES } from './privateAppRoutes/InvoicesRoutes';
import { PURCHASE_ORDERS_ROUTES } from './privateAppRoutes/PurchaseOrdersRoutes';
import { REMOVALS_ROUTES } from './privateAppRoutes/RemovalsRoutes';
import { REPRICER_ROUTES } from './privateAppRoutes/RepricerRoutes';
import { STORAGE_ROUTES } from './privateAppRoutes/storageRoutes';
import { SETTINGS_ROUTES } from './privateAppRoutes/SettingsRoutes';
import { ORDER_FULFILLMET_ROUTES } from './privateAppRoutes/OrderFulfillmentRoutes';
import { FBA_INBOUND_NEW_ROUTES } from "./privateAppRoutes/FbaInboundNew";
import { useSelector } from 'react-redux';
import { PL3_PAYMENTS_ROUTES } from './privateAppRoutes/PL3PaymentsRoutes.js';
import PurchaseOrder from '../containers/newPurchaseOrder/index.js';
import NewScreen from '../containers/newScreen/POScreen.js';

const AdminUsersContainer = lazy(() => import('../containers/admin/usersContainer'));
const ProductsContainer = lazy(() => import('../containers/ProductsContainer'));
const ReferalUsers = lazy(() => import('../containers/ReferalUsers'));
const SalesContainer = lazy(() => import('../containers/SalesContainer'));
const VendorsContainer = lazy(() => import('../containers/VendorsContainer'));
const AccountContainer = lazy(() => import('../containers/fbmsetting/index'));

function RenderIframe(props) {
  const { token } = useSelector((state) => state.auth || {});
  const { route } = props || {};
  const { shipment_id } = useParams();

  return (
    route === 'inbound_shipments' ?
      <iframe is="x-frame-bypass" style={{ height: '100%', width: '100%' }}
        src={`${process.env.API_BASE_URL}/${route}/${shipment_id}?token=${token}`}>
      </iframe> :
      <iframe is="x-frame-bypass" style={{ height: '100%', width: '100%' }}
        src={`${process.env.API_BASE_URL}/${route}?token=${token}`}></iframe>
  )
}

const PRIVATE_APP_ROUTES = [
  {
    path: '/admin/user',
    component: <AdminUsersContainer />,
    group: 'App',
    reducer: 'adminUsers'
  },
  {
    path: '/po/dashboard',
    component: <DashboardContainer />,
    group: 'PO'
  },
  {
    path: '/sales',
    component: <SalesContainer />,
    group: 'App',
    reducer: 'sales'
  },
  {
    path: '/vendors',
    component: <VendorsContainer />,
    group: 'App',
    reducer: 'vendors'
  },
  {
    path: '/products',
    component: <ProductsContainer />,
    group: 'App',
    reducer: ['products', 'Products', 'pendingPoIndices']
  },
  {
    path: '/referal-users',
    component: <ReferalUsers />,
    group: 'App',
    reducer: 'referals'
  },
  {
    path: '/warehouse-vendors',
    component: <RenderIframe route='warehouse_vendors' />,
    group: 'App',
    reducer: []
  },
  // {
  //   path: '/profile',
  //   component: <RenderIframe route='profile' />,
  //   group: 'App',
  //   reducer: []
  // },
  {
    path: '/account',
    component: <AccountContainer />,
    group: 'App',
    reducer: 'account'
  },
  {
    path: '/purchase-order',
    component: <PurchaseOrder />,
    group: 'PO',
    reducer: 'account'
  },
  {
    path: '/po-detail',
    component: <NewScreen />,
    group: 'PO',
    reducer: 'account'
  },
  // {
  //   path: '/inbound-shipments/:shipment_id',
  //   component: <RenderIframe route='inbound_shipments' />,
  //   group: 'App',
  //   reducer: []
  // },
];

PRIVATE_APP_ROUTES.push(...AFFILIATE_ROUTES, ...CATALOG_ROUTES, ...DISTRO_ROUTES,
  ...FBA_INBOUND_ROUTES, ...INVOICES_ROUTES, ...PURCHASE_ORDERS_ROUTES, ...REMOVALS_ROUTES,
  ...REPRICER_ROUTES, ...SETTINGS_ROUTES, ...FBM_ROUTES, ...ORDER_FULFILLMET_ROUTES, ...STORAGE_ROUTES, ...FBA_INBOUND_NEW_ROUTES, ...PL3_PAYMENTS_ROUTES
  );

export { PRIVATE_APP_ROUTES };
