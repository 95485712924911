import React, { lazy } from 'react';

const CustomerInvoiceContainer = lazy(() => import('../../containers/CustomerInvoicesContainer'));
const UserInvoicesContainer = lazy(() => import('../../containers/userInvoicesContainer'));
const MasterRemovalInvoiceContainer = lazy(() => import('../../containers/removalInvoices/masterRemovalInvoicesContainer'));
const UserRemovalInvoicesContainer = lazy(() => import('../../containers/removalInvoices/userRemovalInvoicesContainer'));
const MasterStorageInvoicesContainer = lazy(() => import('../../containers/internalStorageInvoices/MasterInvoicesContainer'));
const InternalStorageInvoices_UserInvoicesContainer = lazy(() => import('../../containers/internalStorageInvoices/UserInvoicesContainer'));
const InvoiceSettingsPage = lazy(() => import('../../components/customerInvoices/Settings.jsx'));
const InvoicesContainer = lazy(() => import('../../containers/InvoicesContainer'));

export const INVOICES_ROUTES = [
  {
    path: '/invoices',
    component: <CustomerInvoiceContainer />,
    group: 'Invoices',
    reducer: ['customerInvoices', 'affiliateAccountSettings'],
  },
  {
    path: '/invoices/:id',
    component: <InvoicesContainer />,
    group: 'Invoices',
    reducer: ['invoices', 'affiliateAccountSettings']
  },
  {
    path: '/user-invoices',
    component: <UserInvoicesContainer />,
    group: 'Invoices',
    reducer: ['userInvoices', 'affiliateAccountSettings']
  },
  {
    path: '/master-removal-invoices',
    component: <MasterRemovalInvoiceContainer />,
    group: 'Invoices',
    reducer: 'masterRemovalInvoices'
  },
  {
    path: '/removal-invoices',
    component: <UserRemovalInvoicesContainer />,
    group: 'Invoices',
    reducer: 'removalInvoices'
  },
  {
    path: '/master-storage-invoices',
    component: <MasterStorageInvoicesContainer />,
    group: 'Invoices',
    reducer: 'masterStorageInvoices'
  },
  {
    path: '/storage-invoices',
    component: <InternalStorageInvoices_UserInvoicesContainer />,
    group: 'Invoices',
    reducer: 'userStorageInvoices'
  },
  {
    path: '/invoices/settings',
    component: <InvoiceSettingsPage />,
    group: 'Invoices',
    reducer: []
  }
];
