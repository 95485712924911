import { cloneDeep } from "lodash";
const initialState = {
  vendors: [],
  users: [],
  fetching: false,
  fetchingVendor: false,
  fetchingUser: false,
  pendingPos: [],
  pending_PoIndex: {},
  total_records: 0,
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  }
};

const allOrders = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case "SET_FILTERS_PENDING_POS_DETAILS":
    case "SET_SORT_OPTIONS_PENDING_POS_DETAILS": {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_PENDING_POS_DETAILS_REQUEST':
    case 'UPDATE_PENDING_POS_REQUEST':
    case 'DELETE_PENDING_POS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case "GET_PENDING_POS_DETAILS_SUCCESS": {
      const { pending_pos, pending_po_index, total_records } = action.payload;
      return {
        ...state,
        fetching: false,
        pendingPos: pending_pos,
        pending_PoIndex: pending_po_index,
        total_records
      };
    }
    case 'SET_PAGINATION_PENDING_POS_DETAILS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'GET_VENDORS_FOR_PENDING_POS_REQUEST': {
      return {
        ...state,
        fetchingVendor: true,
      };
    }
    case 'GET_VENDORS_FOR_PENDING_POS_FAILED':
    case 'DELETE_PENDING_POS_SUCCESS':
    case 'DELETE_PENDING_POS_FAILED': {
      return {
        ...state,
        fetchingVendor: false,
        fetching: false
      };
    }
    case 'GET_VENDORS_FOR_PENDING_POS_SUCCESS': {
      return {
        ...state,
        fetchingVendor: false,
        vendors: action.payload ? [...action.payload] : []
      };
    }
    case 'GET_USERS_FOR_PENDING_POS_REQUEST': {
      return {
        ...state,
        fetchingUser: true,
      }
    }
    case 'GET_USERS_FOR_PENDING_POS_FAILED': {
      return {
        ...state,
        fetchingUser: false,
      }
    }
    case 'GET_USERS_FOR_PENDING_POS_SUCCESS': {
      return {
        ...state,
        fetchingUser: false,
        users: action.payload ? [...action.payload] : []
      };
    }
    case 'GET_PENDING_POS_DETAILS_FAILED':
    case 'UPDATE_PENDING_POS_SUCCESS':
    case 'UPDATE_PENDING_POS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    default:
      return state;
  }
};

export default allOrders;
