import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { fetch as axios } from '../../axios_config';
import { notification } from 'antd';

export const GetAllOrders = createAsyncThunk(
  '/get-all-orders',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const { pagination, filters, sorting } = state.fbm;
      const response = await axios({
        method: 'GET',
        url: `${process.env.AO_API_URL}/order/all-orders/?pagenum=${pagination.page_num}&perpage=${pagination.per_page}`,
        params: { filters: JSON.stringify(filters || []), sorting: JSON.stringify(sorting || []) },
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const ExportOrders = createAsyncThunk(
  '/export-orders',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const { filters, sorting } = state.fbm;
      const response = await axios({
        method: 'GET',
        url: `${process.env.AO_API_URL}/order/export-orders`,
        params: { filters: JSON.stringify(filters || []), sorting: JSON.stringify(sorting || []) },
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetSingleOrder = createAsyncThunk(
  '/get-single-order',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const { filters } = state.fbm;
      const response = await axios({
        method: 'GET',
        url: `${process.env.AO_API_URL}/order/single-order?type=${data.type}`,
        params: { filters: JSON.stringify(filters || []) },
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetFBMCounts = createAsyncThunk(
  '/get-fbm-counts',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const { filters } = state.fbm;
      const response = await axios({
        method: 'GET',
        url: `${process.env.AO_API_URL}/order/get-counts`,
        params: { filters: JSON.stringify(filters || []) },
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetFBMLastVisit = createAsyncThunk(
  '/get-fbm-last-vist',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'GET',
        url: `${process.env.AO_API_URL}/order/last-visit-history`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const FulfilOrder = createAsyncThunk(
  '/fulfil-order',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'POST',
        url: `${process.env.AO_API_URL}/order/mark-completed?type=${data.type}`,
        data,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { error } = data || {};
      if (response) {
        if (error !== "User Wallet not exist") {
          notification.error({
            message: 'Marking Order as Fulfilled',
            description: error || 'Failed To Fulfill',
            top: 65
          });
        }
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const SubmitMatch = createAsyncThunk(
  '/verify-match',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'POST',
        url: `${process.env.AO_API_URL}/order/verify-match?type=${data.type}`,
        data,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateOrder = createAsyncThunk(
  '/update-order',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'PATCH',
        url: `${process.env.AO_API_URL}/order/update-order?type=${data.type}`,
        data,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetMarketPlacesSettingDetails = createAsyncThunk(
  '/lister/marketplace-settings',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.LISTER_BASE_URL}/api/marketplace_settings`,
        data
      });
      return response?.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};
      if (response) {
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateAmazonSettingDetails = createAsyncThunk(
  'lister/amazon-lister-settings',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.LISTER_BASE_URL}/api/amazon_lister/amazon_lister_settings`,
        data
      });
      return response?.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'UPDATING AMAZON SETTING DETAILS',
          description: message || 'Failed To Update',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateWalmartSettingDetails = createAsyncThunk(
  'lister/walmart-lister-settings',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.LISTER_BASE_URL}/api/walmart_lister/walmart_lister_settings`,
        data
      });
      return response?.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'UPDATING WALMART SETTING DETAILS',
          description: message || 'Failed To Update',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateShopifySettingDetails = createAsyncThunk(
  'lister/shopify-lister-settings',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.LISTER_BASE_URL}/api/shopify_lister/shopify_lister_settings`,
        data
      });
      return response?.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'UPDATING SHOPIFY SETTING DETAILS',
          description: message || 'Failed To Update',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateMarketPlaceStatus = createAsyncThunk(
  'lister/update-marketplace-status',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.LISTER_BASE_URL}/api/enable_marketplace`,
        data
      });
      return { ...response?.data, marketplace: data.marketplace, enable_marketplace: data.enable_marketplace }
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'UPDATING MARKETPLACE STATUS',
          description: message || 'Failed To Update',
          top: 65
        });
        return rejectWithValue({
          err: response,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetCreditCards = createAsyncThunk(
  '/get-credit-cards',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'GET',
        url: `${process.env.AO_API_URL}/credit_card/all`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      });
      return response?.data
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'GETTING CREDIT CARDS',
          description: message || 'Getting Credit Card Failed',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const AddCreditCard = createAsyncThunk(
  '/add-credit-card',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'POST',
        url: `${process.env.AO_API_URL}/credit_card/add`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        },
        data
      });
      notification.success({
        message: 'ADDING CREDIT CARD',
        description: 'Successfully Added',
        top: 65
      })
      return response?.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'ADDING CREDIT CARD',
          description: message || 'Failed To Add',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateCreditCard = createAsyncThunk(
  '/update-credit-card',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { id, reqData } = data;
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'PUT',
        url: `${process.env.AO_API_URL}/credit_card/update/${id}`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        },
        data: reqData
      });
      notification.success({
        message: 'UPDATING CREDIT CARD',
        description: 'Successfully Updated',
        top: 65
      })
      return response?.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'UPDATING CREDIT CARD',
          description: message || 'Failed To Update',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateDefaultCreditCard = createAsyncThunk(
  '/update-default-card',
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'POST',
        url: `${process.env.AO_API_URL}/credit_card/set-default/${id}`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        },
      });
      notification.success({
        message: 'UPDATING DEFAULT CREDIT CARD',
        description: 'Successfully Updated',
        top: 65
      })
      return response?.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'UPDATING DEFAULT CREDIT CARD',
          description: message || 'Failed To Add',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const RemoveCreditCard = createAsyncThunk(
  '/remove-credit-card',
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'DELETE',
        url: `${process.env.AO_API_URL}/credit_card/remove/${id}`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        },
      });
      notification.success({
        message: 'REMOVING CREDIT CARD',
        description: 'Successfully Removed',
        top: 65
      })
      return response?.data;
    } catch (error) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'REMOVING CREDIT CARD',
          description: message || 'Failed To Remove',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetPurchasingAccounts = createAsyncThunk(
  '/get-purchasing-account',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'GET',
        url: `${process.env.AO_API_URL}/target_account/all`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      });
      return response?.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'GETTING PURCHASING ACCOUNTS',
          description: message || 'Getting Purchasing Account Failed',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const AddPurchasingAccount = createAsyncThunk(
  '/add-purchasing-account',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'POST',
        url: `${process.env.AO_API_URL}/target_account/add`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        },
        data
      });
      notification.success({
        message: 'ADDING PURCHASING ACCOUNT',
        description: 'Successfully Added',
        top: 65
      })
      return response?.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'ADDING PURCHASING ACCOUNT',
          description: message || 'Failed To Add',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdatePurchasingAccount = createAsyncThunk(
  '/update-purchasing-account',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { id, reqData } = data;
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'PUT',
        url: `${process.env.AO_API_URL}/target_account/update/${id}`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        },
        data: reqData
      });
      notification.success({
        message: 'UPDATING PURCHASING ACCOUNT',
        description: 'Successfully Updated',
        top: 65
      })
      return response?.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'UPDATING PURCHASING ACCOUNT',
          description: message || 'Failed To Update',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const RemovePurchasingAccount = createAsyncThunk(
  '/remove-purchasing-account',
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'DELETE',
        url: `${process.env.AO_API_URL}/target_account/remove/${id}`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        },
      });
      notification.success({
        message: 'REMOVING PURCHASING ACCOUNT',
        description: 'Successfully Removed',
        top: 65
      })
      return response?.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'REMOVING PURCHASING ACCOUNT',
          description: message || 'Failed To Remove',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetUserAddresses = createAsyncThunk(
  '/get-user-addresses',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'GET',
        url: `${process.env.AO_API_URL}/address/all`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      });
      return response?.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'GETTING USER ADDRESSES',
          description: message || 'Getting User Addresses Failed',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const AddUserAddress = createAsyncThunk(
  '/add-user-address',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'POST',
        url: `${process.env.AO_API_URL}/address/add`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        },
        data
      });
      notification.success({
        message: 'ADDING USER ADDRESSES',
        description: 'Successfully Added',
        top: 65
      })
      return response?.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'ADDING USER ADDRESSES',
          description: message || 'Failed To Add',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateUserAddress = createAsyncThunk(
  '/update-user-address',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { id, reqData } = data;
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'PUT',
        url: `${process.env.AO_API_URL}/address/update/${id}`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        },
        data: reqData
      });
      notification.success({
        message: 'UPDATING USER ADDRESSES',
        description: 'Successfully Updated',
        top: 65
      })
      return response?.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'UPDATING USER ADDRESSES',
          description: message || 'Failed To Update',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const RemoveUserAddress = createAsyncThunk(
  '/remove-user-address',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { id, reqData } = data;
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'DELETE',
        url: `${process.env.AO_API_URL}/address/remove/${id}`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        },
        data: reqData
      });
      notification.success({
        message: 'REMOVING USER ADDRESSES',
        description: message || 'Successfully Removed',
        top: 65
      })
      return response?.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'REMOVING USER ADDRESSES',
          description: message || 'Failed To Removed',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateUserAuthToken = createAsyncThunk(
  '/update-user-auth-token',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { sale_support_user_id, reqData } = data;
      const response = await axios({
        method: 'PUT',
        url: `/api/v2/users/${sale_support_user_id}`,
        data: {
          user: reqData
        }
      });
      notification.success({
        message: 'UPDATING USER AUTH TOKEN',
        description: 'Successfully Updated',
        top: 65
      })
      return response?.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'UPDATING USER AUTH TOKEN',
          description: message || 'Failed To Update',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const CreateUpdateWalmartSupplier = createAsyncThunk(
  '/create-update-walmart-supplier',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.AO_API_URL}/unauthorized/add-walmart-supplier`,
        data
      });
      const { success, message, data: { authToken } } = response?.data || {};
      return { success, authToken };
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'CREATING/UPDATING SUPPLIER',
          description: message || 'Failed To CREATE/UPDATE',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const CreateUpdateAmazonSupplier = createAsyncThunk(
  '/create-update-amazon-supplier',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.AO_API_URL}/unauthorized/add-amazon-supplier`,
        data
      });
      const { success, message, data: { authToken } } = response?.data || {};
      return { success: true, authToken };
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'CREATING/UPDATING SUPPLIER',
          description: message || 'Failed To CREATE/UPDATE',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const CreateUpdateShopifySupplier = createAsyncThunk(
  '/create-update-shopify-supplier',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.AO_API_URL}/unauthorized/add-shopify-supplier`,
        data
      });
      const { success, message, data: { authToken } } = response?.data || {};
      return { success: true, authToken };
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'CREATING/UPDATING SUPPLIER',
          description: message || 'Failed To CREATE/UPDATE',
          top: 65
        });
        return rejectWithValue({
          err: data,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetSuppliers = createAsyncThunk(
  'get-suppliers',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'GET',
        url: `${process.env.AO_API_URL}/supplier/all`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
)

export const UpdateSupplier = createAsyncThunk(
  'update-supplier',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { id, reqData } = data;
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'PUT',
        url: `${process.env.AO_API_URL}/supplier/update/${id}`,
        data: reqData,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
)

export const CheckWalmartSupplier = createAsyncThunk(
  'check-walmart-supplier',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'POST',
        url: `${process.env.AO_API_URL}/supplier/check-walmart-supplier`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'CHECKING WALMART SUPPLIER',
          description: message || 'Failed To Fetch',
          top: 65
        });
        return rejectWithValue({
          message,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
)

export const CheckAmazonSupplier = createAsyncThunk(
  'check-amazon-supplier',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'POST',
        url: `${process.env.AO_API_URL}/supplier/check-amazon-supplier`,
        data,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'CHECKING AMAZON SUPPLIER',
          description: message || 'Failed To Fetch',
          top: 65
        });
        return rejectWithValue({
          message,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
)

export const CheckShopifySupplier = createAsyncThunk(
  'check-shopify-supplier',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'POST',
        url: `${process.env.AO_API_URL}/supplier/check-shopify-supplier`,
        data,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response.data;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};

      if (response) {
        notification.error({
          message: 'CHECKING SHOPIFY SUPPLIER',
          description: message || 'Failed To Fetch',
          top: 65
        });
        return rejectWithValue({
          message,
          status: status,
          success: false
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
)

export const UpdateListerSource = createAsyncThunk(
  'update-lister-source',
  async (reqData, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'POST',
        url: `${process.env.AO_API_URL}/order/update-lister-source`,
        data: reqData,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
)

export const GetSalesSummary = createAsyncThunk(
  '/get-sales-summary',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const { startDate, endDate, marketplaceSource, sold } = data;
      const response = await axios({
        method: 'GET',
        url: `${process.env.AO_API_URL}/order/get-graph-data`,
        params: {
          startDate,
          endDate,
          marketplaceSource,
          sold
        },
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return { ...response.data, marketplaceSource};
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetDashboardStats = createAsyncThunk(
  '/get-dashboard-stats',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const { startDate, endDate, marketplaceSource } = data;
      const response = await axios({
        method: 'GET',
        url: `${process.env.AO_API_URL}/order/dashboard-stats`,
        params: {
          startDate,
          endDate,
          marketplaceSource
        },
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return { ...response.data, marketplaceSource };
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateSupplierOrder = createAsyncThunk(
  '/update-supplier-order',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'PATCH',
        url: `${process.env.AO_API_URL}/order/update-supplier-order`,
        data,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetNearestWarehouse = createAsyncThunk(
  '/get-nearest-warehouse',
  async (postalCode, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const { filters } = state.fbm;
      const response = await axios({
        method: 'GET',
        url: `${process.env.AO_API_URL}/order/get-nearest-warehouse?postalCode=${postalCode}`,
        params: { filters: JSON.stringify(filters || []) },
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetRepricerStatus = createAsyncThunk(
  '/get-repricer-status',
  async (email, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.LISTER_BASE_URL}/api/fetch_internal_repricer_status`,
        data: {
          email
        }
      })
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

const fbm = createSlice({
  name: 'fbm',
  initialState: {
    loading: false,
    countsLoading: false,
    pagination: {
      page_num: 1,
      per_page: 25
    },
    filters: [],
    sorting: [],
    totalCount: 0,
    counts: {},
    lastVisit: [],
    singleOrder: {},
    dashboard: {
    },
    orders: {
      list: [],
    },
    orderFulfillment: {
    },
    settings: {
      amazonSettings: {},
      walmartSettings: {},
      shopifySettings: {},
      creditCards: [],
      addresses: [],
      purchasingAccounts: [],
      suppliers: []
    },
    salesSummary: {
      amazon: {},
      walmart: {},
      shopify: {}
    },
    nearestWarehouse: {}
  },
  reducers: {
    setState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    clearState(state, action) {
      return {
        loading: false,
        pagination: {
          page_num: 1,
          per_page: 25
        },
        filters: [],
        sorting: [],
        totalCount: 0,
        counts: {},
        lastVisit: [],
        singleOrder: {},
        dashboard: {
        },
        orders: {
          list: [],
        },
        orderFulfillment: {
        },
        settings: {
          amazonSettings: {},
          walmartSettings: {},
          shopifySettings: {},
          creditCards: [],
          addresses: [],
          purchasingAccounts: [],
          suppliers: []
        },
        salesSummary: {
          amazon: {},
          walmart: {},
          shopify: {}
        },
        nearestWarehouse: {}
      }
    },
    addFilters(state, { payload: data }) {
      const filters = [...state.filters];
      const { field, operator, value } = data;
      const filterIndex = findIndex(filters, { field });
      if (filterIndex !== -1) {
        filters[filterIndex] = { field, operator, value };
      } else {
        filters.push({ field, operator, value });
      }
      state["filters"] = filters;
    },
    addSortings(state, { payload: data }) {
      const sorting = [...state.sorting];
      const { column, sort } = data;
      const index = findIndex(sorting, { column });
      if (index !== -1) {
        sorting[index] = data;
      } else {
        sorting.push(data);
      }
      state["sorting"] = sorting;
    },
    setPageNum(state, { payload: data }) {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...data
        }
      }
    },
    setPerPage(state, { payload: data }) {
      return {
        ...state,
        pagination: {
          page_num: 1,
          ...data
        }
      }
    },
    clearFilters(state) {
      return {
        ...state,
        pagination: {
          page_num: 1,
          per_page: 25
        },
        filters: [],
        sorting: [],
      }
    }
  },
  extraReducers: {
    [GetAllOrders.pending]: (state, action) => ({
      ...state,
      loading: true,
      totalCount: 0,
      orders: {
        list: [],
      },
    }),
    [GetAllOrders.fulfilled]: (state, action) => {
      const { success, list, totalCount } = action?.payload?.data || {};
      return {
        ...state,
        loading: false,
        totalCount,
        orders: {
          list,
        },
      }
    },
    [GetAllOrders.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        totalCount: 0,
        orders: {
          list: [],
        },
      }
    },
    [GetSingleOrder.pending]: (state, action) => ({
      ...state,
      singleOrder: {},
      loading: true,
    }),
    [GetSingleOrder.fulfilled]: (state, action) => {
      const { item, count, nearestWarehouse, orderItemsIds, processingInfo, orderAttempt } = action?.payload?.data || {};
      return {
        ...state,
        singleOrder: {
          orderItemsIds,
          item,
          count,
          nearestWarehouse,
          processingInfo,
          orderAttempt
        },
        loading: false,
      }
    },
    [GetSingleOrder.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetFBMCounts.pending]: (state, action) => ({
      ...state,
      countsLoading: true
    }),
    [GetFBMCounts.fulfilled]: (state, action) => {
      return {
        ...state,
        counts: action.payload.data?.counts,
        countsLoading: false
      }
    },
    [GetFBMCounts.rejected]: (state, action) => {
      return {
        ...state,
        countsLoading: false
      }
    },
    [GetFBMLastVisit.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [GetFBMLastVisit.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        lastVisit: action.payload.data?.lastVisit,
      }
    },
    [GetFBMLastVisit.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [FulfilOrder.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [FulfilOrder.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [FulfilOrder.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [SubmitMatch.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [SubmitMatch.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [SubmitMatch.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdateOrder.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [UpdateOrder.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdateOrder.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdateSupplierOrder.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [UpdateSupplierOrder.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdateSupplierOrder.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetMarketPlacesSettingDetails.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [GetMarketPlacesSettingDetails.fulfilled]: (state, action) => {
      const { data } = action?.payload || {};
      return {
        ...state,
        loading: false,
        settings: {
          ...state.settings,
          amazonSettings: data?.amazon_settings,
          walmartSettings: data?.walmart_settings,
          shopifySettings: data?.shopify_settings
        }
      }
    },
    [GetMarketPlacesSettingDetails.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        settings: {
          ...state.settings,
          amazonSettings: { enable_marketplace: false },
          walmartSettings: { enable_marketplace: false },
          shopifySettings: { enable_marketplace: false }
        }
      }
    },
    [UpdateAmazonSettingDetails.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [UpdateAmazonSettingDetails.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdateAmazonSettingDetails.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdateWalmartSettingDetails.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [UpdateWalmartSettingDetails.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdateWalmartSettingDetails.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdateShopifySettingDetails.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [UpdateShopifySettingDetails.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdateShopifySettingDetails.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdateMarketPlaceStatus.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [UpdateMarketPlaceStatus.fulfilled]: (state, action) => {
      const { marketplace, enable_marketplace } = action?.payload || {};
      let tempSettings = {
        ...state.settings,
      }
      if (marketplace === 'Amazon') {
        tempSettings = {
          ...tempSettings,
          amazonSettings: {
            ...state.settings.amazonSettings,
            enable_marketplace
          }
        }
      } else if (marketplace === 'Walmart') {
        tempSettings = {
          ...tempSettings,
          walmartSettings: {
            ...state.settings.walmartSettings,
            enable_marketplace
          }
        }
      } else {
        tempSettings = {
          ...tempSettings,
          shopifySettings: {
            ...state.settings.shopifySettings,
            enable_marketplace
          }
        }
      }
      return {
        ...state,
        loading: false,
        settings: tempSettings
      }
    },
    [UpdateMarketPlaceStatus.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [AddCreditCard.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [AddCreditCard.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [AddCreditCard.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdateCreditCard.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [UpdateCreditCard.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [UpdateCreditCard.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdateDefaultCreditCard.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [UpdateDefaultCreditCard.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [UpdateDefaultCreditCard.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [RemoveCreditCard.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [RemoveCreditCard.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [RemoveCreditCard.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetCreditCards.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [GetCreditCards.fulfilled]: (state, action) => {
      const { success, data } = action?.payload || {};
      return {
        ...state,
        loading: false,
        settings: {
          ...state.settings,
          creditCards: data
        }
      }
    },
    [GetCreditCards.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetPurchasingAccounts.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [GetPurchasingAccounts.fulfilled]: (state, action) => {
      const { success, data } = action?.payload || {};
      return {
        ...state,
        loading: false,
        settings: {
          ...state.settings,
          purchasingAccounts: data
        }
      }
    },
    [GetPurchasingAccounts.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetUserAddresses.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [GetUserAddresses.fulfilled]: (state, action) => {
      const { success, data } = action?.payload || {};
      return {
        ...state,
        loading: false,
        settings: {
          ...state.settings,
          addresses: data
        }
      }
    },
    [GetUserAddresses.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [AddUserAddress.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [AddUserAddress.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [AddUserAddress.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdateUserAddress.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [UpdateUserAddress.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [UpdateUserAddress.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [RemoveUserAddress.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [RemoveUserAddress.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [RemoveUserAddress.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [AddPurchasingAccount.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [AddPurchasingAccount.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [AddPurchasingAccount.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdatePurchasingAccount.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [UpdatePurchasingAccount.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [UpdatePurchasingAccount.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [RemovePurchasingAccount.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [RemovePurchasingAccount.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [RemovePurchasingAccount.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdateUserAuthToken.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [UpdateUserAuthToken.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [UpdateUserAuthToken.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [CreateUpdateWalmartSupplier.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [CreateUpdateWalmartSupplier.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [CreateUpdateWalmartSupplier.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [CreateUpdateAmazonSupplier.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [CreateUpdateAmazonSupplier.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [CreateUpdateAmazonSupplier.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [CreateUpdateShopifySupplier.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [CreateUpdateShopifySupplier.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [CreateUpdateShopifySupplier.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetSuppliers.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [GetSuppliers.fulfilled]: (state, action) => {
      const { success, data } = action?.payload || {};
      return {
        ...state,
        loading: false,
        settings: {
          ...state.settings,
          suppliers: data
        }
      }
    },
    [GetSuppliers.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdateSupplier.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [UpdateSupplier.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [UpdateSupplier.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [CheckAmazonSupplier.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [CheckAmazonSupplier.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [CheckAmazonSupplier.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        settings: {
          ...state.settings,
          amazonSettings: {
            ...state.settings.amazonSettings,
            enable_marketplace: false
          }
        }
      }
    },
    [CheckWalmartSupplier.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [CheckWalmartSupplier.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [CheckWalmartSupplier.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        settings: {
          ...state.settings,
          walmartSettings: {
            ...state.settings.walmartSettings,
            enable_marketplace: false
          }
        }
      }
    },
    [CheckShopifySupplier.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [CheckShopifySupplier.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [CheckShopifySupplier.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        settings: {
          ...state.settings,
          shopifySettings: {
            ...state.settings.shopifySettings,
            enable_marketplace: false
          }
        }
      }
    },
    [UpdateListerSource.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [UpdateListerSource.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [UpdateListerSource.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetSalesSummary.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [GetSalesSummary.fulfilled]: (state, action) => {
      const { success, data, marketplaceSource } = action?.payload || {};
      return {
        ...state,
        loading: false,
        salesSummary: {
          ...state.salesSummary,
          [marketplaceSource]: {
            ...data.combinedData
          },
        }
      }
    },
    [GetSalesSummary.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetDashboardStats.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [GetDashboardStats.fulfilled]: (state, action) => {
      const { success, combinedData, marketplaceSource } = action?.payload || {};
      return {
        ...state,
        loading: false,
        salesSummary: {
          ...state.salesSummary,
          [marketplaceSource]: {
            ...combinedData
          },
        }
      }
    },
    [GetDashboardStats.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetNearestWarehouse.pending]: (state, action) => ({
      ...state,
      nearestWarehouse: {},
      loading: true,
    }),
    [GetNearestWarehouse.fulfilled]: (state, action) => {
      const { nearestWarehouse } = action?.payload?.data || {};
      return {
        ...state,
        nearestWarehouse: nearestWarehouse,
        loading: false,
      }
    },
    [GetNearestWarehouse.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    }
  }
});

const { reducer, actions } = fbm;

export const {
  setState,
  clearStore,
  addFilters,
  addSortings,
  setPageNum,
  setPerPage,
  clearFilters,
  clearState
} = actions;

export default reducer;
