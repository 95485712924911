import React from 'react'
import { InputWrapper } from '../style'
import { Form } from "react-bootstrap"

const Index = (props) => {
  const { type, placeholder, onChange, value, disabled,className,width,marginBottom, readOnly, height, inputRef, onKeyDown } = props

  return (
    <InputWrapper width={width} marginBottom={marginBottom} height={height}>
      <Form.Group>
      <span className={className}>
          <Form.Control
            disabled ={disabled}
            value={value}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            readOnly={readOnly}
            ref={inputRef}
            onKeyDown={onKeyDown}
          />
          </span>
      </Form.Group>
    </InputWrapper>
  )
}

export default Index
