import React, { lazy } from 'react';

const ShipmentsContainer = lazy(() => import('../../containers/ShipmentsContainer'));

export const FBA_INBOUND_ROUTES = [
  {
    path: '/transport-plans',
    component: <ShipmentsContainer />,
    group: 'FBA Inbound',
    reducer: 'shipments'
  },
];
