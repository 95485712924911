import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosRails, { fetch as axios } from '../../axios_config';
import { handleRejectedResponse } from './utils';
import { awsAxios } from '../../axios_config';
import { notification } from 'antd';


export const GetReadyForShipmentDetails = createAsyncThunk(
  'newFbaFlow/GetReadyForShipmentDetails',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.FBA_INBOUND_API_URL}/po-ship-details`,
      {
        params: data
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const GetReadyFor2DLabelDetails = createAsyncThunk(
  'newFbaFlow/GetReadyFor2DLabelDetails',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get( `${process.env.FBA_INBOUND_API_URL}/ready-for-labels`,
      {
        params: data
      });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Ready For 2D Label Details', rejectWithValue);
    }
  }
);

export const GetReadyToCloseDetails = createAsyncThunk(
  'newFbaFlow/GetReadyToCloseDetails',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get( `${process.env.FBA_INBOUND_API_URL}/ready-to-close`,
      {
        params: data
      });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Ready To Close Details', rejectWithValue);
    }
  }
);

export const GetCompletedShipmentDetails = createAsyncThunk(
  'newFbaFlow/GetCompletedShipmentDetails',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.FBA_INBOUND_API_URL}/completed-shipments`,
      {
        params: data
      });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Completed Shipment Details', rejectWithValue);
    }
  }
);

export const UpdateBatchName = createAsyncThunk(
  'newFbaFlow/UpdateBatchName',
  async ({batch_id, batch_name}, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.FBA_INBOUND_API_URL}/edit-batch-name`, {
        batch_id,
        batch_name
      });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Batch Name', rejectWithValue);
    }
  }
);

export const DeleteAllShipment = createAsyncThunk(
  'newFbaFlow/DeleteAShipments',
  async (shipment_batch_id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${process.env.FBA_INBOUND_API_URL}/delete-all-shipment/${shipment_batch_id}`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Delete All Shipment', rejectWithValue);
    }
  }
);

export const UpdateShipmentName = createAsyncThunk(
  'newFbaFlow/UpdateShipmentName',
  async ({shipment_id, shipment_name}, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.FBA_INBOUND_API_URL}/edit-shipment-name/${shipment_id}`, {
        shipment_name
      });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Shipment Name', rejectWithValue);
    }
  }
);
  
export const GetRecievedTrackings = createAsyncThunk(
  'newFbaFlow/GetRecievedTrackings',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get( `${process.env.API_BASE_URL}api/v2/received_trackings/shipments`,
      {
        params: data
      });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Recieved Trackings', rejectWithValue);
    }
  }
);
export const GetRelatedShipments = createAsyncThunk(
  'newFbaFlow/GetRelatedShipments',
  async ({ batch_id, shipment_id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.FBA_INBOUND_API_URL}/related-shipments`,
      {
        params: {
          batch_id,
          shipment_id
        }
      });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Related Shipments', rejectWithValue);
    }
  }
);

const uploadToS3 = async ({ urls, files }) => {
  const uploadPromises = urls.map(async (url, index) => {
    const file = files[index];
    
    try {
      await awsAxios({
        method: 'PUT',
        url,
        data: file,
        headers: {
          'Content-Type': file.type
        },
      });
      console.log(`File ${file.name} uploaded successfully.`);
      return true;
    } catch (error) {
      console.error(`Error uploading file ${file.name}:`, error);
      return false;
    }
  });

  return Promise.all(uploadPromises);
};

export const GetPreSignedUrl = createAsyncThunk(
  '/get-pre-signed-url',
  async ({ files, file_list }, { rejectWithValue }) => {
    try {

      const response = await axios.get(`${process.env.API_BASE_URL}/api/v2/staging_items/presigned_urls`, {
        params: {
          file_list: JSON.stringify(file_list)
        }
      });
      const urls = response?.data?.urls?.map(entry => {
        const splitUrl = entry.url.split('?');
        return splitUrl[0];
    });
      await uploadToS3({ urls, files });
      return response?.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Upload Product  Pictures', rejectWithValue);
    }
  }
);

export const GetPreSignedUrlForReceivedTracking = createAsyncThunk(
  '/get-pre-signed-url-for-received-tracking',
  async ({ files, file_list }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}api/v2/received_trackings/presigned_urls`, {
        params: {
          file_list: JSON.stringify(file_list)
        }
      });
      const urls = response?.data?.urls;
      await uploadToS3({ urls, files });
      return response?.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Upload Product  Pictures', rejectWithValue);
    }
  }
);

export const GetBatchDetails = createAsyncThunk(
  'newFbaFlow/GetBatchDetails',
  async (batch_id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.FBA_INBOUND_API_URL}/batch-details/${batch_id}`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Batch Details', rejectWithValue);
    }
  }
);

export const MarkLabelsComplete = createAsyncThunk(
  'newFbaFlow/mark-labels-complete',
  async (shipment_id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.FBA_INBOUND_API_URL}/mark-labels-complete/${shipment_id}`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Mark Label Complete', rejectWithValue);
    }
  }
);

export const SendItemsToShipment = createAsyncThunk(
  'newFbaFlow/send-to-shipment',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.API_BASE_URL}api/v2/staging_items/${id}/send_to_shipment`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Send To Shipment', rejectWithValue);
    }
  }
);

export const AttachMatchingPo = createAsyncThunk(
  'newFbaFlow/attach-matching-po',
  async ({ id, pending_po_index_id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.API_BASE_URL}/api/v2/received_trackings/${id}/attach_matching_po`, { pending_po_index_id });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const ChangeShipmentQuantity = createAsyncThunk(
  'newFbaFlow/change-shipment-quantity',
  async ({ shipment_id, new_quantity, inbound_shipment_item_id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.FBA_INBOUND_API_URL}/change-shipment-quantity/${shipment_id}`,
      {
        new_quantity, inbound_shipment_item_id
      });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Change Shipment Quantity', rejectWithValue);
    }
  }
);

export const GetAllMatchingPo = createAsyncThunk(
  'newFbaFlow/get-all-matching-po',
  async ({ search }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}/api/v2/received_trackings/all_matching_pos`,
      {
        params: { search }
      });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get All Matching PO', rejectWithValue);
    }
  }
);

export const QuickSyncShipment = createAsyncThunk(
  'newFbaFlow/quick-sync-shipment',
  async (shipment_id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.FBA_INBOUND_API_URL}/sync-transport-plan/${shipment_id}`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Quick Sync Shipment', rejectWithValue);
    }
  }
);

export const AddNewSku = createAsyncThunk(
  'newFbaFlow/add-new-sku',
  async ({ id, sku }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.API_BASE_URL}/api/v2/pos/${id}/new_sku`, {
        sku
      });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Add New SKU', rejectWithValue);
    }
  }
);

export const GetUsers = createAsyncThunk(
  'newFbaFlow/get-users',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}/api/v2/pending_pos/users`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Users', rejectWithValue);
    }
  }
);

export const GetOtherSkusForAsin = createAsyncThunk(
  'newFbaFlow/get-other-skus-for-asin',
  async ({ vendorasin_id, user_id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}/api/v2/seller_skus/other_skus_for_asin`, {
        params: { vendorasin_id, user_id }
      });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Other Skus For Asin', rejectWithValue);
    }
  }
);

export const ConfirmChangedSku = createAsyncThunk(
  'newFbaFlow/confirm-changed-sku',
  async ({ po_id, vendorasin_id, seller_sku_id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.API_BASE_URL}/api/v2/pos/${po_id}/change_sku`,
      { vendorasin_id, seller_sku_id }
      );
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Confirm Changed SKU', rejectWithValue);
    }
  }
);

export const GetRecievingHistory = createAsyncThunk(
  'newFbaFlow/get-recieving-history',
  async (pending_po_index_id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.FBA_INBOUND_API_URL}/all-po-received-history/${pending_po_index_id}`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Recieving History', rejectWithValue);
    }
  }
);
export const GetStoredItems = createAsyncThunk(
  'newFbaFlow/get-stored-items',
  async (pending_po_index_id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}api/v2/pending_pos/${pending_po_index_id}/all_stored_items`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Stored Items', rejectWithValue);
    }
  }
);

const fbaInboundShipment = createSlice({
  name: "fbaInboundShipment",
  initialState: {
    readyForShipment: {
      result: [],
      loading: false,
      count: 0
    },
    readyFor2DLabel: {
      result: [],
      loading: false,
      count: 0
    },
    readyToClose: {
      result: [],
      loading: false,
      count: 0
    },
    completedShipment: {
      result: [],
      loading: false,
      count: 0
    },
    received_trackings: [],
    loading: false,
    success: false,
    err: '',
    uploadLoading: false,
    fetchingMatchingPos: false,
    fetchingAttachedPos: false,
    matchingPos: [],
    changingQuantity: false,
    otherSkus: [],
    usersList: [],
    relatedShipments: [],
    recievedHistory: [],
    storedItems: [],
  },
  reducers: {
    setFbaInboundState(state, { payload: { field, value } }) {
      state[field] = value;
    },
  },
  extraReducers: {
    [GetReadyForShipmentDetails.pending]: (state) => {
      state.readyForShipment.loading = true;
    },
    [GetReadyForShipmentDetails.fulfilled]: (state, action) => {
      state.readyForShipment.loading = false;
      state.readyForShipment.result = action.payload.result;
      state.readyForShipment.count = action.payload.count;
    },
    [GetReadyForShipmentDetails.rejected]: (state, action) => {
      state.readyForShipment.loading = false;
      state.err = action.error.message;
    },
    [GetReadyFor2DLabelDetails.pending]: (state) => {
      state.readyFor2DLabel.loading = true;
    },
    [GetReadyFor2DLabelDetails.fulfilled]: (state, action) => {
      state.readyFor2DLabel.loading = false;
      state.readyFor2DLabel.result = action.payload.result;
      state.readyFor2DLabel.count = action.payload.count;
    },
    [GetReadyFor2DLabelDetails.rejected]: (state, action) => {
      state.readyFor2DLabel.loading = false;
      state.err = action.error.message;
    },
    [GetReadyToCloseDetails.pending]: (state, action) => {
      state.readyToClose.loading = true;
    },
    [GetReadyToCloseDetails.fulfilled]: (state, action) => {
      state.readyToClose.result = action.payload.result;
      state.readyToClose.count = action.payload.count;
      state.readyToClose.loading = false;
    },
    [GetReadyToCloseDetails.rejected]: (state, action) => {
      state.readyToClose.loading = false;
      state.err = action.error.message;
    },
    [GetCompletedShipmentDetails.pending]: (state) => {
      state.completedShipment.loading = true;
    },
    [GetCompletedShipmentDetails.fulfilled]: (state, action) => {
      state.completedShipment.result = action.payload.result;
      state.completedShipment.count = action.payload.count;
      state.completedShipment.loading = false;
    },
    [GetCompletedShipmentDetails.rejected]: (state, action) => {
      state.completedShipment.loading = false;
      state.err = action.error.message;
    },
    [GetRecievedTrackings.pending]: (state) => {
      state.loading = true;
    },
    [GetRecievedTrackings.fulfilled]: (state, action) => {
      state.loading = false;
      state.received_trackings = action.payload.received_trackings;
    },
    [GetRecievedTrackings.rejected]: (state, action) => {
      state.loading = false;
    },
    [GetPreSignedUrl.pending]: (state) => {
      state.uploadLoading = true;
    },
    [GetPreSignedUrl.fulfilled]: (state, action) => {
      state.uploadLoading = false;
    },
    [GetPreSignedUrl.rejected]: (state, action) => {
      state.uploadLoading = false;
      state.err = action.payload;
    },
    [GetPreSignedUrlForReceivedTracking.pending]: (state) => {
      state.uploadLoading = true;
    },
    [GetPreSignedUrlForReceivedTracking.fulfilled]: (state) => {
      state.uploadLoading = false;
      notification.success({
        message: "Image Uploaded Successfully",
        top: 65
      });
    },
    [GetPreSignedUrlForReceivedTracking.rejected]: (state) => {
      state.uploadLoading = false;
    },
    [GetBatchDetails.pending]: (state) => {
      state.loading = true;
    },
    [GetBatchDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.batchDetails = action.payload.result;
    },
    [GetBatchDetails.rejected]: (state, action) => {
      state.loading = false;
    },
    [MarkLabelsComplete.pending]: (state) => {
      state.loading = true;
    },
    [MarkLabelsComplete.fulfilled]: (state, action) => {
      state.loading = false
    },
    [MarkLabelsComplete.rejected]: (state, action) => {
      state.loading = false;
    },
    [SendItemsToShipment.pending]: (state) => {
      state.loading = true;
    },
    [SendItemsToShipment.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = action.payload.success;
    },
    [SendItemsToShipment.rejected]: (state, action) => {
      state.loading = false;
    },
    [AttachMatchingPo.pending]: (state) => {
      state.fetchingAttachedPos = true;
    },
    [AttachMatchingPo.fulfilled]: (state, action) => {
      state.fetchingAttachedPos = false;
    },
    [AttachMatchingPo.rejected]: (state, action) => {
      state.fetchingAttachedPos = false;
    },
    [GetAllMatchingPo.pending]: (state) => {
      state.fetchingMatchingPos = true;
    },
    [GetAllMatchingPo.fulfilled]: (state, action) => {
      state.matchingPos = action.payload.pending_po_indices;
      state.fetchingMatchingPos = false;
    },
    [GetAllMatchingPo.rejected]: (state, action) => {
      state.fetchingMatchingPos = false;
    },
    [ChangeShipmentQuantity.pending]: (state) => {
      state.changingQuantity = true;
    },
    [ChangeShipmentQuantity.fulfilled]: (state, action) => {
      state.changingQuantity = false;
    },
    [ChangeShipmentQuantity.rejected]: (state, action) => {
      state.changingQuantity = false;
    },
    [QuickSyncShipment.pending]: (state) => {
      state.loading = true;
    },
    [QuickSyncShipment.fulfilled]: (state) => {
      state.loading = false;
    },
    [QuickSyncShipment.rejected]: (state) => {
      state.loading = false;
    },
    [AddNewSku.pending]: (state) => {
      state.loading = true;
    },
    [AddNewSku.fulfilled]: (state) => {
      state.loading = false;
    },
    [AddNewSku.rejected]: (state) => {
      state.loading = false;
    },
    [GetOtherSkusForAsin.pending]: (state) => {
      state.loading = true;
    },
    [GetOtherSkusForAsin.fulfilled]: (state, action) => {
      state.loading = false;
      state.otherSkus = action.payload.skus;
    },
    [GetOtherSkusForAsin.rejected]: (state) => {
      state.loading = false;
    },
    [ConfirmChangedSku.pending]: (state) => {
      state.loading = true;
    },
    [ConfirmChangedSku.fulfilled]: (state) => {
      state.loading = false;
    },
    [ConfirmChangedSku.rejected]: (state) => {
      state.loading = false;
    },
    [GetUsers.pending]: (state) => {
      state.loading = true;
    },
    [GetUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.usersList = action.payload.users;
    },
    [GetUsers.rejected]: (state) => {
      state.loading = false;
    },
    [UpdateBatchName.pending]: (state) => {
      state.loading = true;
    },
    [UpdateBatchName.fulfilled]: (state) => {
      state.loading = false;
      notification.success({
        message: "Batch Name Update",
        description: 'Batch Name updated successfully.',
        top: 65
      });
    },
    [UpdateBatchName.rejected]: (state) => {
      state.loading = false;
    },
    [GetRelatedShipments.pending]: (state) => {
      state.loading = true;
    },
    [GetRelatedShipments.fulfilled]: (state, action) => {
      state.loading = false;
      state.relatedShipments = action?.payload?.result?.inbound_shipments;
    },
    [GetRelatedShipments.rejected]: (state) => {
      state.loading = false;
    },
    [GetRecievingHistory.pending]: (state) => {
      state.loading = true;
    },
    [GetRecievingHistory.fulfilled]: (state, action) => {
      state.loading = false;
      state.recievedHistory = action?.payload?.receivedHistories;
    },
    [GetRecievingHistory.rejected]: (state) => {
      state.loading = false;
    },
    [GetStoredItems.pending]: (state) => {
      state.loading = true;
    },
    [GetStoredItems.fulfilled]: (state, action) => {
      state.loading = false;
      state.storedItems = action?.payload?.history;
    },
    [GetStoredItems.rejected]: (state) => {
      state.loading = false;
    },
    [UpdateShipmentName.pending]: (state) => {
      state.loading = true;
    },
    [UpdateShipmentName.fulfilled]: (state) => {
      state.loading = false;
      notification.success({
        message: "Shipment Name Update",
        description: 'Shipment Name updated successfully.',
        top: 65
      });
    },
    [UpdateShipmentName.rejected]: (state) => {
      state.loading = false;
    },
    [DeleteAllShipment.pending]: (state) => {
      state.loading = true;
    },
    [DeleteAllShipment.fulfilled]: (state) => {
      state.loading = false;
      notification.success({
        message: "Shipment Deleted",
        description: 'Shipment deleted successfully.',
        top: 65
      });
    },
    [DeleteAllShipment.rejected]: (state) => {
      state.loading = false;
    }
  }
})

const { reducer, actions } = fbaInboundShipment;

export const {
  setFbaInboundState
} = actions;

export default reducer;
