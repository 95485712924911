import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Drawer, Spin, Modal, Tooltip, notification } from "antd";
import { debounce, flatMap } from 'lodash';

import Button from "../../components/uiComponent/button";
import Input from "../../components/uiComponent/inputs/searchinput/";
import Select from "../../components/uiComponent/select";
import CustomEmailModal from "./email-modal";
import Steppers from "../newPurchaseOrder/stepper";
import { BsArrowLeftShort } from "react-icons/bs";
import { IoMdArrowForward } from "react-icons/io";
import ProductToPurchaseOrder from "../newPurchaseOrder/firstStepperdata/productToPurchaseOrder";
import Table from "./POTable";
import DeleteIcon from "../../assets/images/delete.svg"
import RestartIcon from "../../assets/images/restart.svg"
import RotateIcon from "../../assets/images/arrows-rotate.svg"
import CheckSS from "../../assets/images/ss-check-icon.svg"

import {
  GetGraftPo,
  setFbaShipmentState,
  CreateListing,
  ResetListing,
  DeleteListing,
  GetTopStats,
  ProductsList,
  GetGatedPos,
  DeleteListingItems
} from "../../slices/fba-shipment";

import "../../components/radioCard/style.css";

import { POWrapper } from "./style";

const PoDetailScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    loading,
    poData,
    topStats,
    success,
    isInventoryListing,
    isPoCount
  } = useSelector((store) => store.fbaShipment) || {};
  const { currentUser } = useSelector((state) => state.auth || {});
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const poId = params.get('id');

  const [openDrawer, setOpenDrawer] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [draftProducts, setDraftProducts] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [uploadsBarVisible, setUploadsBarVisible] = useState(!!poData?.feed);
  const [visibility, setVisibility] = useState('visible');
  const [emailModal, setEmailModal] = useState(false);
  const [email, setEmail] = useState('');


  const [filters, setFilters] = useState({
    filter: { search: '' },
    pagination: { page_num: 1, per_page: 25 }
  });
  const statusOptions = [
    { value: "gated", label: "GATED" },
    { value: "ok", label: "OK" },
    { value: "all", label: "ALL" },
  ];
  const Orders = [
    {
      title: (
        <>
          Total
          <br /> Units
        </>
      ),
      value: topStats?.total_units || 0,
      key: "total",
    },
    {
      title: (
        <>
          Total
          <br /> Bundles
        </>
      ),
      value: topStats?.total_bundles || 0,
      key: "shipped",
    },
    {
      title: (
        <>
          Total
          <br /> Dollars
        </>
      ),

      value: `$${topStats?.total_dollars || 0}`,
      key: "missing",
    },
    {
      title: (
        <>
          Total
          <br /> SKUs
        </>
      ),
      value: topStats?.total_skus || 0,
      key: "due-date",
    },
  ];

  useEffect(() => {
    dispatch(GetTopStats({ poId }));
  }, []);

  useEffect(() => {
    if (open) setDraftProducts({
      ...(
        Object.fromEntries(
          Object.entries(poData).filter(([key, value]) => !['delivery_informations', 'feed']?.includes(key))
        )
      ),
      pos: poData?.pos?.filter(({ sku_status }) => !['OK'].includes(sku_status)),
      delivery_informations_attributes: [...poData?.delivery_informations]
    });
    setUploadsBarVisible(!!poData?.feed);
  }, [poData, open]);

  useEffect(() => {
    const { pagination, filter } = filters || {};
    dispatch(GetGraftPo({ poId, per_page: pagination?.per_page, page: pagination?.page_num, filters: filter })).then(({ payload }) => {
      if (payload?.message === 'Draft Po not found!') navigate('/purchase-order');
    });
  }, [filters]);

  useEffect(() => {
    if (success) {
      const { pagination, filter } = filters || {};
      dispatch(GetGraftPo({ poId, per_page: pagination?.per_page, page: pagination?.page_num, filters: filter }));
      dispatch(setFbaShipmentState({ field: 'success', value: false }));
      dispatch(GetTopStats({ poId }));
    }
    if (isInventoryListing) {
      const { pagination, filter } = filters || {};
      dispatch(GetGraftPo({ poId, per_page: pagination?.per_page, page: pagination?.page_num, filters: filter }));
      dispatch(setFbaShipmentState({ field: 'isInventoryListing', value: false }));
      setOpenDrawer(false);
    }
    if (isPoCount === 0) {
      const { pagination, filter } = filters || {};
      dispatch(GetGraftPo({ poId, per_page: pagination?.per_page, page: pagination?.page_num, filters: filter }));
      dispatch(setFbaShipmentState({ field: 'isPoCount', value: '' }));
    }
  }, [success, isInventoryListing, isPoCount]);

  useEffect(() => {
    const poItems = poData?.pos?.map(({
      id,
      upc,
      vendor_upc,
      asin,
      vendortitle,
      order_quantity,
      sku,
      cost,
      profit,
      vendor,
      bundle_quantity,
      image,
      vendor_address,
      sku_status,
      matching_skus_count,
      vendorasin_id,
      userId,
      attached_to_different_asin
    }) => {
      const { address_line_1, city, zipcode, addressable_type } = vendor_address || {};
      return {
        id,
        UPC: upc,
        vendorUPC: vendor_upc,
        name: vendortitle,
        quantity: order_quantity || 0,
        asin,
        marketplace: 'Marketplace 1',
        SKU: sku || 'N/A',
        status: sku_status,
        cost,
        profit,
        vendor: { vendorName: vendor, vendorAddress: `${zipcode || ''} ${addressable_type || ''} ${address_line_1 || ''} ${city || ''}` },
        bundleQTY: bundle_quantity,
        availableQuantities: 100,
        image,
        matchingCount: matching_skus_count,
        vendorAsinId: vendorasin_id,
        userId: poData?.user_id,
        poData: poData,
        attachedToDifferentAsin: attached_to_different_asin
      };
    });
    setRowData(poItems);
  }, [poData]);

  const handlePagination = (number, key) => {
    const value = Number(number);
    let paginationClone = { ...filters?.pagination };
    if (key === "pageLimit")
      paginationClone = { page_num: 1, per_page: value };
    else
      paginationClone = { ...filters?.pagination, page_num: value };
    setFilters({
      ...filters,
      pagination: paginationClone
    });
  };

  const validateEmail = (email) => {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const refreshGridClick = () => {
    const { pagination, filter } = filters || {}
    dispatch(GetGraftPo({ poId, per_page: pagination?.per_page, page: pagination?.page_num, filters: filter }));
  };

  const searPoItem = debounce((value) => {
    setFilters({
      ...filters,
      filter: { ...filters?.filter, search: value },
      pagination: {
        ...filters?.pagination,
        page_num: 1
      }
    });
  }, 1000);

  return (
    <Spin tip='Loading...' spinning={loading}>
      <POWrapper>
        <div className="po-header-buttons">
          <Button
            onClick={() => navigate('/purchase-order', { state: { isViewDraftPO: true } })}
            text={
              <>
                <span className="icon-arrow-left cursor-pointer"></span>Back to
                All Drafts
              </>
            }
            className="outlined"
          ></Button>
          <h4>
            PO#: <span>{poData?.po_name || 'N/A'}</span>
          </h4>
        </div>
        {
          poData?.feed && <div className="po-uploads-bar">
            <div className="po-uploads-left">
              <h2 className={poData?.feed?.step === 5 && "in-progress-status-ss"}>
                {
                  poData?.feed?.step < 5 ? 'Current Processing in Progress' : 'Processing Successfully Finished'
                }
              </h2>
            </div>
            <div className="po-uploads-right">
              <div className="box">
                <p className="po-uploads-heading">Name</p>
                <div className="po-uploads-name">
                  <Tooltip title={poData?.feed?.name}><p>{poData?.feed?.name}</p></Tooltip>
                </div>
              </div>
              <div className="box">
                <p className="po-uploads-heading">Error Message</p>
                <Tooltip title={poData?.feed?.error_message || 'N/A'}><p className="text-ellipse">{poData?.feed?.error_message || "N/A"}</p></Tooltip>
              </div>
              <div className="box">
                <p className="po-uploads-heading">Total PO Items</p>
                <p>{poData?.feed?.total_pos}</p>
              </div>
              <div className="box">
                <p className="po-uploads-heading">Items With Success</p>
                <p>{poData?.feed?.processed}</p>
              </div>
              <div className="box">
                <p className="po-uploads-heading">Items With Error</p>
                <p>{poData?.feed?.errored}</p>
              </div>
              <div className="ss-po-wrapper-progress">
                <div className="box Po-ss-progress-wrapper">
                  <span className="po-uploads-heading">Status</span>
                  <div className="progress-bar-wrapper-ss">
                    <div className="progress-bar-item-ss">
                      <div className={poData?.feed?.step === 1 ? "progress-bar-ss in-process-bar-ss" :
                        poData?.feed?.step > 1 ? "progress-bar-ss" : ''} />
                      <p>Creating Listing</p>
                    </div>
                    <div className="progress-bar-item-ss">
                      <div className={poData?.feed?.step === 2 ? "progress-bar-ss in-process-bar-ss" :
                        poData?.feed?.step > 2 ? "progress-bar-ss" : 'progress-bar-ss empty-progress-bar-ss'} />
                      <p>Checking for Hazmat</p>
                    </div>
                    <div className="progress-bar-item-ss">
                      <div className={poData?.feed?.step === 3 ? "progress-bar-ss in-process-bar-ss" :
                        poData?.feed?.step > 3 ? "progress-bar-ss" : 'progress-bar-ss empty-progress-bar-ss'} />
                      <p>Checking Gated SKUs</p>
                    </div>
                    <div className="progress-bar-item-ss">
                      <div className={poData?.feed?.step === 4 ? "progress-bar-ss in-process-bar-ss" :
                        poData?.feed?.step > 4 ? "progress-bar-ss" : 'progress-bar-ss empty-progress-bar-ss'} />
                      <p>Finishing Processing</p>
                    </div>
                    {
                      poData?.feed?.step < 5 ? <img
                        className="rotate-icon-ss"
                        onClick={() => {
                          const { pagination, filter } = filters || {}
                          dispatch(GetGraftPo({ poId, per_page: pagination?.per_page, page: pagination?.page_num, filters: filter }));
                        }}
                        src={RotateIcon}
                        alt="RotateIcon"
                      /> :
                        <img className="rotate-icon-ss" src={CheckSS} alt="CheckSS" />
                    }
                  </div>
                </div>
                <Tooltip
                  title={
                    !poData?.pos?.length ? 'There are no items in PO' :
                      !poData?.feed
                        ? 'Please make sure create listing first'
                        : !['partially errored', 'processed'].includes(poData?.feed?.status)
                          ? 'Please wait until feed is processed'
                          : ''
                  }
                >
                  <span>
                    <Button
                      disabled={!['partially errored', 'processed'].includes(poData?.feed?.status) || !poData?.pos?.length}
                      onClick={() => {
                        setOpen(true);
                        dispatch(GetGatedPos({ poId, filters: { search: "", listing_status: "gated" } }));
                      }}
                      className="confirm-po-wrapper" text={
                        <span>Confirm PO <IoMdArrowForward style={{ fontSize: 16, color: '#fff', }} /></span>
                      } />
                  </span>
                </Tooltip>
              </div>
              <div className="box">
                <div className="po-uploads-actions">
                  <Button
                    onClick={() => dispatch(ResetListing(poData?.feed?.id))}
                    disabled={poData?.feed?.step === 5}
                    text={
                      <>
                        Restart<img src={RestartIcon} />
                      </>
                    }
                    className="outlined danger restart-button"
                  ></Button>
                  <Button
                    onClick={() => dispatch(DeleteListing(poData?.feed?.id))}
                    text={
                      <>
                        Delete<img src={DeleteIcon} />
                      </>
                    }
                    className="outlined delete-button"
                  ></Button>
                </div>
              </div>

            </div>
          </div>
        }
        <div className="po-header-bar">
          <div className="po-header-left">
            <Tooltip
              title={poData?.feed && !['partially errored', 'processed', 'errored'].includes(poData?.feed?.status) ?
                'please wait until feed is processed' : ['partially errored', 'errored', 'processed'].includes(poData?.feed?.status) ?
                  'Please first delete listing before adding more items' : ''}>
              <span>
                <Button
                  text="Add items"
                  disabled={['partially errored', 'processed', 'processing', 'errored'].includes(poData?.feed?.status)}
                  onClick={() => {
                    if (['partially errored', 'processed', 'processing', 'errored'].includes(poData?.feed?.status)) return;
                    setOpenDrawer(true);
                    dispatch(ProductsList({ draft_po_id: poId }));
                    setVisibility('visible')
                  }}
                />
              </span>
            </Tooltip>
            <div className="input-search">
              <Input
                className="h-32"
                placeholder="UPC/ASIN/SKU/FNSKU"
                onChange={(e) => {
                  searPoItem(e.target.value);
                }}
              />
            </div>
            <div className="po-select">
              <Select
                placeholder="Listing Status"
                className="h-32"
                options={statusOptions}
                onChange={({ value }) => {
                  setFilters({
                    ...filters,
                    filter: {
                      ...filters?.filter,
                      listing_status: value === 'all' ? '' : value
                    },
                    pagination: {
                      ...filters?.pagination,
                      page_num: 1
                    }
                  });
                }}
              />
            </div>
          </div>
          <div className="po-header-right">
            {Orders.map((tiles) => (
              <div className={`orders d-flex fbm-order ${tiles.key}`}>
                <div className="d-flex justify-content-between align-items-center order-inner-text-wrapper">
                  <Tooltip title={tiles.value}>
                    <h1 className="mb-0 order-inner-value">{tiles.value}</h1>
                  </Tooltip>
                </div>
                <span className="fs-12 color-secondary">{tiles.title}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="po-items-delete-wrapper" style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          <Tooltip title={poData?.feed?.status && "Please First Delete Listing"}>
            <div>
              <Button
                onClick={() => {
                  if (flatMap(Object.values(selectedRows))?.length) setOpenDeleteModal(true);
                }}
                disabled={!flatMap(Object.values(selectedRows))?.length || poData?.feed?.status}
                text={
                  <>
                    Delete<img src={DeleteIcon} />
                  </>
                }
                className="delete-items-button"
              ></Button>
            </div>
          </Tooltip>
        </div>
        <Table
          className={uploadsBarVisible ? 'uploads-bar-visible' : 'uploads-bar-hidden'}
          rowData={rowData}
          setRowData={setRowData}
          total={poData?.pos_count}
          handlePagination={handlePagination}
          refreshGridClick={refreshGridClick}
          pagination={filters?.pagination}
          poId={poId}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          filters={filters}
          poData={poData}
        />
        {
          !poData?.feed && <div className="buttons-wrapper">
            <div>
              <Tooltip
                title={
                  poData?.feed ? 'Please first delete previous listing before create listing again' : !poData?.pos?.length ?
                    'Please first add items in the PO' : ''
                }
              >
                <span>
                  <Button
                    disabled={poData?.feed || !poData?.pos?.length}
                    text={
                      <>Create Listing And Gated Check</>
                    }
                    className="outlined"
                    onClick={() => {
                      setEmailModal(true);
                      setEmail(currentUser?.email);
                    }}
                  ></Button>
                </span>
              </Tooltip>
            </div>
          </div>
        }
          <Drawer
            title={
              <div className="drawer-header">
                <BsArrowLeftShort onClick={() => setOpenDrawer(false)} />
                <p>Add Items</p>
              </div>

            }
            placement="right"
            onClose={() => setOpenDrawer(false)}
            visible={openDrawer}
            width="729px"
            className={`update-product-drawer-wrapper ${visibility === 'visible' ? 'visible-po-ss' : 'hidden-po-ss'}`}
          >
            <ProductToPurchaseOrder
              setVisibility={setVisibility}
              addItemButton={true}
              draft_po_id={poData?.id}
              vendor_id={poData?.vendor_id}
              openDrawer={openDrawer}
              po_name={poData?.po_name}
              vendorId={poData?.vendor_id}
              setOpenDrawer={setOpenDrawer}
            />
          </Drawer>
        <Modal
          width={780}
          visible={open}
          centered
          className="stepper-modal-ui"
          footer={null}
          keyboard={false}
          onCancel={() => setOpen(false)}
        >
          <Steppers
            setOpen={setOpen}
            open={open}
            poData={draftProducts}
            totalProducts={topStats?.total_skus}
          />
        </Modal>
        <Modal
          width={380}
          bodyStyle={{ minHeight: 50 }}
          visible={openDeleteModal}
          centered
          onOk={() => {
            dispatch(DeleteListingItems({
              draft_po_id: poId,
              po_ids: flatMap(Object.values(selectedRows))
            })).then(() => {
              setSelectedRows({});
            });
            setOpenDeleteModal(false);
          }}
          okText="Delete"
          cancelText="Cancel"
          onCancel={() => setOpenDeleteModal(false)}
        >
          <p style={{
            fontWeight: 'bold'
          }}>Are you sure you want to delete items</p>
        </Modal>
        <CustomEmailModal
          visible={emailModal}
          onCancel={() => setEmailModal(false)}
          setEmail={setEmail}
          className="custom-email-drawer"
          email={email}
          onEmailSubmit={(mail_to) => {
            if (!validateEmail(mail_to)) {
              return notification.error({
                message: "Create Listing",
                description: 'Not a Valid Email',
                top: 5
              });
            }
            dispatch(CreateListing({
              vendor_id: poData?.vendor_id,
              mail_to,
              draft_po_id: poId
            }));
            setEmailModal(false);
            setEmail('');
          }}
        />
      </POWrapper>
    </Spin>
  );
};

export default PoDetailScreen;
