import Styled from 'styled-components';

const InputWrapper = Styled.div`
  width: ${(props) => props.width};
  label{
    margin-bottom:4px;
    color: ${({ theme }) => theme['regent_gray']};
    font-size: ${({ theme }) => theme['base-font-size-small']};
  }
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '16px')};
  .small-input{
      input{
        height:25px ;
        font-size: ${({ theme }) => theme['medium-font-size']};
         width:65px;
         font-size: ${({ theme }) => theme['font-size-small']};
      }

  }
  .large-input{
    input{
      height:48px;
      padding:8px 12px;
      min-width:163px
    }
  }
  .h-32{
    &.icon-input{
      input{
        padding: 0 12px 0px 33px;
      }
    }
    &.bottom-space{
      margin-bottom:20px;
    }
    &.w-238{
      input{
      min-width: 238px;
      }
    }
    &.w-60{
      input{
      min-width: 58px;
       max-width: 60px;
      }
    }
    &.w-194{
      input{
         min-width:194px;
       max-width: 194px;
      }
    }
    &.w-212{
      min-width: 212px;
    }
    &.w-276{
      max-width:276px;
      min-width: 276px;
      margin-bottom:20px;
    }
    &.w-123{
      max-width:123px;
      min-width: 123px;
    }
    &.w-80{
        max-width:103px;
      min-width: 103px; 
    }
    input{
      height:32px;
      font-size: ${({ theme }) => theme['medium-font-size']};
    }
    .left-arrow{
      right: 5px;
      top: 8px;
    }
  }
    .add-border{
      input{
      border:2px solid  ${({ theme }) => theme['primary-color']};           
      }
      }
    .add-complete-box{
      input{
      border:2px solid  ${({ theme }) => theme['primary-color']};
      color: ${({ theme }) => theme['primary-color']};
      font-size:'14px';
      font-weight:600;
      text-align:center;
      }
    }
  .two-input{
    align-items:center;
    .seperation{
      width: 7px;
    height: 1px;
    background-color: black;
    margin: 0 5px;
    }
    input{
      font-size: ${({ theme }) => theme['medium-font-size']};
    }
  }
  input{
    border: ${(props) => (props.borderless ? '1px solid transparent' : '1px solid #CFD8DC')};
    color: ${({ theme }) => theme['cutty_sark']};
    font-size: ${({ theme }) => theme['medium-font-size']};
    line-height:1.7;
    background: ${(props) => (props.background ? props.background : '')};
    height: ${(props) => props.height && props.height};

    &.order-date{
      min-width:212px;
      height: 30px;

    }
    &.danger-border{
    border-color:#CF0909;
    &:focus{
      border-color:#CF0909;
    }
  }
    &:focus{
      border-color:#CFD8DC;
      box-shadow:none;
      color: ${({ theme }) => theme['cutty_sark']};
    }
    &::placeholder{
      font-size: ${({ theme }) => theme['medium-font-size']};
       color: ${({ theme }) => theme['placeholder-color']};
    }
  }
  .form-text{
    color: ${({ theme }) => theme['lynch-color']};
    font-size: ${({ theme }) => theme['font-size-small']};
  }
  .progress-status{
      .progress{
        min-width:200px;
        height:4px;
        .progress-bar{
          background-color: ${({ theme }) => theme['gold_color']};
        }
      }
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background: url("https://sale-support-logos.s3.us-west-1.amazonaws.com/calendar.svg") no-repeat;
        width: 14px;
        height: 14px;
        border-width: thin;
        margin-top:2px ;
    }
    .amount-input {
      input {
        font-size: 12px;
      }
    }
  `
const PhoneNumberWrapper = Styled.div`
  margin-bottom:15px;
  label{
    margin-bottom:4px;
    color: ${({ theme }) => theme['regent_gray']};
    font-size: ${({ theme }) => theme['base-font-size-small']};
  }
  .PhoneInput{
    border: 1px solid #CFD8DC;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    &.--PhoneInput-color--focus{
      color:transparent;
    }
  }
  .PhoneInputInput{
    border: none;
  }

  `;
const SearchInputWrapper = Styled.div`
    .postion-relative{
      &.scan-search{
        input{
         height:48px;
         width:100%;
        font-size:1.5rem;
        color:${({ theme }) => theme['cutty_sark']};
        font-size:18px;
        padding: 0 14px;
        &::placeholder{
              font-size:18px;
                              color:${({ theme }) => theme['cutty_sark']};
        }
        }
           .icon-scancode{
            font-size:24px;
            position:absolute;
            right:10px;
            top:5px;
            &::before{
                font-size:36px;
            }
        }
        
      }
      &.search-box{
        input{
          border-radius: 0px;
        border-left: none;
        border-right: none;
        &:focus{
          box-shadow:none;
        }
        }
 
      }

      &.large-search{
        input{
        height:60px;
        width:100%;
        font-size:1.5rem;
        color:${({ theme }) => theme['cutty_sark']};
        font-size:24px;
        padding: 0 14px;
      }
      input:placeholder-shown {
        text-overflow: ellipsis;
          }
          .return-arrow{
            font-size:24px;
            position:absolute;
            right:10px;
            top:18px;
          }
      }
  
      &.drawer-search-input{
        input{
         padding:8px 42px 8px 12px;
        height: 48px;
        }
        svg{
        font-size:16px;
        position:absolute;
        right:16px;
        top:17px;
      }
      }
      input{
        height:32px;
        width: ${(props) => (props.width ? `${props.width}` : '100%')};
        font-size:12px;
        width: ${(props) => (props.width ? `${props.width}` : "")};
          padding-left: ${(props) => (props.iconLeft ? "24px" : "")};
        &::placeholder{
          font-size: ${({ theme }) => theme['base-font-size-small']};
          color: #ccc;
        }
      }
      &.location-search {
      width: 184px;
      input {
        min-width: 184px;
      }
    }
      svg{
        font-size:12px;
        position:absolute;
        right:10px;
        left: ${(props) => (props.iconLeft ? "10px" : "")};
        top:9px;
      }
    }
    .selected-search{
          input{
            border:2px solid ${({ theme }) => theme['primary-color']};
            box-shadow: 4px 4px 25px 0px #6FD5F640;
          }
          
        }
        .height-change{
          input{
            height:40px;
            border-top: none;
            border-left: none;
            border-right: none;
          }
          svg{
              top:13px;
            }
        }
    
  `
export { InputWrapper, PhoneNumberWrapper, SearchInputWrapper };
