import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { Spin } from 'antd';
import { PaymentWrapper } from "./style";
import { Formik } from "formik";
import { object, string, number } from "yup";
import { useDispatch, useSelector } from "react-redux";
import Header from "../header/header";
import states, { countryOptions } from "../../constants/states";
import Select from "../uiComponent/select/index";
import Button from "../uiComponent/button/index";
import { Row, Col, Form } from "react-bootstrap";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Input from "../uiComponent/inputs/labelInput/index";
import {
  // SubscribeToPlan,
  // QBSubscription,
  CreateSubscription,
  addCard,
  setState,
  // SaveCardMisc,
  // GetAccessToken,
} from "../../slices/store";
import { GetCurrentUser } from "../../slices/auth";

const AddPayment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { planId, paymentLoading, skipBasicLoading, squareTokenLoading } = useSelector(state => state?.store || {});
  const { currentUser } = useSelector(state => state?.auth || {});

  const [selectedState, setSelectedState] = useState("AL");
  const [selectedCountry, setSelectedCountry] = useState('US')
  // const [card, setCard] = useState('');
  // const [squareLoading, setSquareLoading] = useState(true);
  const paymentType = 'subscription';
  // const platformType = 'square';
  const platformType = 'nmi';

  const { id: userId } = currentUser || {};
  const currentYear =  new Date().getFullYear();

  // const squareIntegration = async() => {
  //   const payments = await Square.payments(process.env.SUB_SQUARE_APP_ID, process.env.SUB_SQUARE_LOCATION_ID);
  //   const card = await payments.card();
  //   await card.attach('#card-container');
  //   setSquareLoading(false);
  //   setCard(
  //     card
  //   );
  // }

  // useEffect(() => {
  //   squareIntegration()
  // }, [])

  const getCurrentUserAndNavigate = async() => {
    await dispatch(GetCurrentUser());
    // const reducers = ['catalogJobs', 'processedCatalogs'];
    // await dispatch({ type: 'RESET_APP', reducers });
    // navigate('/catalog-jobs');
  }

  // const handleAddCard = async (values) => {
  //   const { name, postalCode, country, addressLine1, addressLine2, city } = values;
  //   let squareToken = '';
  //   await dispatch(setState({ field: 'squareTokenLoading', value: true}));
  //   const result = await card.tokenize();
  //   if (result.status === 'OK') {
  //     const { token } = result;
  //     squareToken = token;
  //   } else {
  //     await dispatch(setState({ field: 'squareTokenLoading', value: false}));
  //     return;
  //   }
  //   if (!isEmpty(squareToken)) {
  //     const body = {
  //       platform: platformType,
  //       payment_type: paymentType,
  //       token: squareToken,
  //       card_name: name,
  //       address: {
  //         address_line_1: addressLine1,
  //         address_line_2: addressLine2,
  //         city,
  //         state: selectedState,
  //         postal_code: postalCode,
  //         country
  //       }
  //     }
  //     const result = await dispatch(addCard(body));
  //     if (!result.error) {
  //       const subscription = {
  //         subscription_plan_id: planId,
  //         user_id: userId
  //       }
  //       const subResponse = await dispatch(CreateSubscription({ subscription }));
  //       if (!subResponse.error) getCurrentUserAndNavigate();
  //     }
  //   }
  // };
  
  const handleAddCard = async (values) => {
  const { number, expMonth, expYear, cvc, name, postalCode, country, addressLine1, addressLine2, city, state } = values || {};
    const obj = JSON.stringify({
      a: number,
      b: expMonth,
      c: expYear,
      d: cvc
    });
    const token = Buffer.from(obj).toString("base64");
    const body = {
      platform: platformType,
      payment_type: paymentType,
      token,
      card_name: name,
      address: {
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        city,
        state: selectedCountry === 'US'? selectedState: state,
        postal_code: postalCode,
        country: selectedCountry
      }
    }
    const result = await dispatch(addCard(body));
    if (!result.error) {
      const subscription = {
        subscription_plan_id: planId,
        user_id: userId
      }
      const subResponse = await dispatch(CreateSubscription({ subscription }));
      if (!subResponse.error) getCurrentUserAndNavigate();
    }
    
  };

  // const handleAddCard = async (values) => {
  //   const { name, number, expMonth, expYear, cvc, postalCode, country, streetAddress, city } = values;
  //   if (
  //     isEmpty(name) ||
  //     isEmpty(number) ||
  //     isEmpty(expMonth) ||
  //     isEmpty(expYear) ||
  //     isEmpty(cvc) ||
  //     !postalCode ||
  //     isEmpty(country) ||
  //     isEmpty(streetAddress) ||
  //     isEmpty(city)
  //   ) return;
  //   const card = {
  //     number,
  //     expMonth,
  //     expYear,
  //     cvc,
  //     name,
  //     default: true,
  //     address: {
  //       postalCode,
  //       country,
  //       region: selectedState,
  //       streetAddress,
  //       city
  //     }
  //   };
  //   const cardResponse = await dispatch(GetAccessToken({ card }));
  //   if (!cardResponse.error) {
  //     const { value: token } = cardResponse?.payload || {};
  //     if (token) {
  //       const obj = JSON.stringify({
  //         a: number,
  //         b: expMonth,
  //         c: expYear,
  //         d: cvc,
  //         e: postalCode,
  //         f: country,
  //         g: selectedState,
  //         h: streetAddress,
  //         i: city,
  //         j: name

  //       });
  //       const users_data = Buffer.from(obj).toString("base64");
  //       const charge = {
  //         token,
  //         users_data,
  //         name
  //       };
  //       const result = await dispatch(addCard({ charge }));
  //       if (!result.error) {
  //         await dispatch(SaveCardMisc({ users_data, userId }));
  //         const subcribeResponse = await dispatch(
  //           SubscribeToPlan({
  //             subscription: {
  //               subscription_plan_id: planId,
  //               user_id: userId
  //             }
  //           })
  //         );
  //         const qb_subscription = {
  //           qb_plan_id: planId,
  //           user_id: userId
  //         }
  //         if(!subcribeResponse.error) {
  //           const qbResponse = await dispatch(QBSubscription({qb_subscription}));
  //           if (!qbResponse.error) getCurrentUserAndNavigate();
  //         }
  //       }
  //     }
  //   }
  // };

  const schema = object().shape({
    name: string().required("Enter your card name!"),
    number: string().required("Enter your card number!"),
    expMonth: number()
      .min(1, "Invalid month")
      .max(12, "Invalid month")
      .required("Enter expiry month!"),
    expYear: number()
      .min(currentYear, "Invalid year")
      .required("Enter expiry year!"),
    cvc: string().required("Enter CVC!"),
    city: string().required("Enter city!"),
    postalCode: string().required("Enter postal code!"),
    addressLine1: string().required("Enter address!"),
    state: selectedCountry === 'US'? string(): string().required("Enter State/Region!")
  });

  const subscribeBasicPlan = async() => {
    if(planId === 1) {
      const response = await dispatch(CreateSubscription({
        subscription: {
          subscription_plan_id: planId,
          user_id: userId
        }
      }));
      if (!response.error) getCurrentUserAndNavigate();
    };
  };

  const handleStateChange = ({value}) => setSelectedState(value);
  const handleCountryChange = ({value}) => setSelectedCountry(value);

  const disableSkipBtn = planId === 2 || skipBasicLoading || paymentLoading || squareTokenLoading;

  return (
    <PaymentWrapper>
      <Formik
        validationSchema={schema}
        onSubmit={(values) => handleAddCard(values)}
        initialValues={{
          name: "",
          number: "",
          expMonth: "",
          expYear: "",
          cvc: "",
          country: "",
          city: "",
          postalCode: "",
          addressLine1: "",
          addressLine2: "",
          state: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header login={true} />
            <div className="select_paln ">
              <div className="arrow-box d-flex grid-column-gap-10 mb-35">
                <BiLeftArrowAlt
                  className="cursor-pointer color-primary"
                  onClick={() => navigate(-1)}
                />
                <h1 className="color-secondary mb-0">Add Payment Method</h1>
              </div>
              <div className="mt-4 add-payment height-calc">
                {/* <Spin tip='Loading Form...' spinning={squareLoading}> */}
                <Row className="justify-content-center">
                  <Col lg={10} md={10} sm={12} xs={12}>
                    <div>
                      <h2 className="color-primary">Card Information</h2>
                      <span className="fs-16 caption-color d-block">
                        Please add a valid Credit Card your monthly
                        subscription. You won't be charged for first month and
                        we will remind you before your 30-Day trial ends.
                      </span>
                      <span className="fs-16 caption-color">
                        You can cancel subscription any time.
                      </span>
                      <div className="pt-36">
                        <h6 className="fs-16 fw-bold">
                          Add Credit Card Credentials
                        </h6>
                        <Row className="m-0 pt-4">
                          <Col md={3} className="ps-0">
                            <Input
                              label="Name on Card"
                              placeholder="Name Here"
                              onChange={handleChange}
                              value={values.name}
                              inputStatus={touched.name && errors.name}
                              name="name"
                              onBlur={handleBlur}
                              className="h-32"
                            />
                          </Col>
                           { ['qb', 'nmi'].includes(platformType) ? (<>
                            <Col md={3}>
                              <Input
                                label="Card Number"
                                placeholder="000000000000"
                                onChange={handleChange}
                                value={values.number}
                                inputStatus={touched.number && errors.number}
                                name="number"
                                onBlur={handleBlur}
                                className="h-32"
                              />
                            </Col>
                            <Col md={2}>
                              <Input
                                label="Expiration Month"
                                placeholder="MM"
                                onChange={handleChange}
                                value={values.expMonth}
                                inputStatus={touched.expMonth && errors.expMonth}
                                name="expMonth"
                                onBlur={handleBlur}
                                className="h-32"
                              />
                            </Col>
                            <Col md={2}>
                              <Input
                                label="Expiration Year"
                                placeholder="YYYY"
                                onChange={handleChange}
                                value={values.expYear}
                                inputStatus={touched.expYear && errors.expYear}
                                name="expYear"
                                onBlur={handleBlur}
                                className="h-32"
                              />
                            </Col>
                            <Col md={2}>
                              <Input
                                label="CVC"
                                placeholder="000"
                                onChange={handleChange}
                                value={values.cvc}
                                inputStatus={touched.cvc && errors.cvc}
                                name="cvc"
                                onBlur={handleBlur}
                                className="h-32"
                              />
                            </Col></>
                          ) : (
                            <Col >
                              <body class="dark-mode">
                                <form id="payment-form">
                                  <div id="card-container"></div>
                                </form>
                                <div id="payment-status-container"></div>
                              </body>
                            </Col>
                          )
                          }
                        </Row>
                        <div className="pt-36">
                          <h6 className="fs-16 fw-bold">
                            Add Billing Address
                          </h6>
                          <Row className="m-0 pt-4">
                            <Col md={4} className="ps-0">
                              {/* <Input
                                label="Address Title"
                                placeholder="Address Holder Name"
                                className="h-32"
                              /> */}
                              <Input
                                label="Address Line 1"
                                placeholder="Address Line 1"
                                value={values.addressLine1}
                                inputStatus={touched.addressLine1 && errors.addressLine1}
                                name="addressLine1"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className="h-32"
                              />
                              <Input
                                label="Postal Code"
                                placeholder="0000"
                                className="h-32"
                                value={values.postalCode}
                                inputStatus={touched.postalCode && errors.postalCode}
                                name="postalCode"
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={4}>
                              {/* <Input
                                label="Address Line 1"
                                placeholder="Address Line 1"
                                className="h-32"
                              /> */}
                              <Input
                                label="Address Line 2"
                                placeholder="Address Line 2"
                                className="h-32"
                                value={values.addressLine2}
                                inputStatus={touched.addressLine2 && errors.addressLine2}
                                name="addressLine2"
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              {
                                selectedCountry === 'US'
                                ? <Select
                                    label="Region"
                                    options={states}
                                    onChange={handleStateChange}
                                    defaultValue={{ value: 'AL', label: 'Alabama'}}
                                  />
                                : <Input
                                    label="State"
                                    placeholder="State"
                                    className="h-32"
                                    value={values.state}
                                    inputStatus={touched.state && errors.state}
                                    name="state"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                              }
                              
                            </Col>
                            <Col md={4}>
                            <Input
                                label="City"
                                placeholder="City"
                                className="h-32"
                                value={values.city}
                                inputStatus={touched.city && errors.city}
                                name="city"
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              <Select
                                label="Country"
                                options={countryOptions}
                                onChange={handleCountryChange}
                                placeholder="Country"
                                className="h-32"
                                defaultValue={{ value: 'US', label: 'US'}}
                              />
                            </Col>
                            <Col md={12} className="pt-4">
                              <div className="d-flex gap-2 justify-content-end">
                              <Button 
                                text={skipBasicLoading? "Skipping...": "Skip"}
                                disabled = {disableSkipBtn}
                                className="outlined"
                                type="button"
                                onClick={() => subscribeBasicPlan()}
                              />
                              <Button
                                type="submit"
                                text={
                                  paymentLoading || squareTokenLoading ? "Adding Card..." : "Add Card"
                                }
                                className="primary"
                                disabled={paymentLoading || squareTokenLoading}
                              />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* </Spin> */}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </PaymentWrapper>
  );
};

export default AddPayment;
