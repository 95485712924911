import React, { lazy } from 'react';

const AffiliateContainer = lazy(() => import('../../containers/affiliate/affiliateContainer'));
const ProfitCalculationManagerDashboardContainer = lazy(() => import('../../containers/profitCalculationManager/DashboardGridContainer'));
const ProfitCalculationManagerInvoicesContainer = lazy(() => import('../../containers/profitCalculationManager/InvoicesContainer'));
const AccountSettingsContainer = lazy(() => import('../../containers/affiliate/accountSettingsContainer'));

export const AFFILIATE_ROUTES = [
  {
    path: '/affiliate',
    component: <AffiliateContainer />,
    group: 'Affiliate',
    reducer: 'affiliate',
  },
  {
    path: '/affiliate/account-settings',
    component: <AccountSettingsContainer />,
    group: 'Affiliate',
    reducer: 'affiliateAccountSettings'
  },
  {
    path: '/affiliate/profit-calculation-manager/dashboard',
    component: <ProfitCalculationManagerDashboardContainer />,
    group: 'Affiliate',
    reducer: 'profitCalculationManagerDashboard'
  },
  {
    path: '/affiliate/profit-calculation-manager/invoices',
    component: <ProfitCalculationManagerInvoicesContainer />,
    group: 'Affiliate',
    reducer: 'affiliateInvoices'
  }
];
