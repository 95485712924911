import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Box,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TextField } from "@mui/material";
import { Radio } from 'antd';
import AgGrid from '../../components/ag-grid-table/index'
import Button from '../../components/uiComponent/button'
import AddIcon from '../../assets/images/newImages/add-icon.svg';
import Input from '../../components/uiComponent/inputs/input'
import Modal from '../../components/uiComponent/modal'
import SkuNotChange from '../../assets/images/newImages/no-sku-change.svg';
import SkuNotFound from '../../assets/images/newImages/no-sku-found.svg';
import AmazonSKu from '../../assets/images/newImages/amazon-sku.svg';
import WalmartSKu from '../../assets/images/newImages/walmart-sku.svg';
import GraphSKu from '../../assets/images/newImages/grapgh-sku.svg';
import Product from '../../assets/images/newImages/product.svg';
import SearchInput from '../../components/uiComponent/inputs/searchinput'
import ArrowBack from '../../assets/images/newImages/back-arrow.svg';
import { useDispatch } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { Tooltip, notification, Spin } from "antd";
import { ceil, uniq, flatMap, debounce, isEmpty } from 'lodash';
// import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import ProductImage from "../../assets/images/product-img.svg";
import MarketplaceImage from "../../assets/images/newImages/amazon-fba.svg";
import { TableWrapper,ModalTableWrapper } from "./style";
import EditIcon from "../../assets/images/edit-icon.svg"
import DisabledEditIcon from "../../assets/images/disabled-edit-icon.svg"
import Pagination from "../../components/pagination";

import { UpdateQuantity, UpdateCost, GetTopStats, GetGraftPo } from "../../slices/fba-shipment";
import { GetOtherSkusForAsin, AddNewSku, ConfirmChangedSku } from "../../slices/fba-inbound-new";

let gridApi;
const POTable = ({
  rowData,
  total,
  handlePagination,
  refreshGridClick,
  pagination,
  poId,
  setSelectedRows,
  selectedRows,
  className,
  filters
}) => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { otherSkus, loading } = useSelector((state) => state.fbaInboundShipment);
  const { page_num, per_page } = pagination;
  const [editQty, setEditQty] = useState('');
  const [editBundleQty, setEditBundleQty] = useState('');
  const [editCost, setEditCost] = useState('');
  const [changeSku,setChangeSKu]=useState(false)
  const [addSku,setAddSku]=useState(false)
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [skuValue, setSkuValue] = useState('');
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [inputActive, setInputActive] = useState(false);

  const CustomEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);

    const inputRef = React.createRef();



    useEffect(() => {
      const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
          props.setEditQty('');
          setValue(props.value);
        } else if (!inputRef.current) {
          props.setEditQty('');
          setValue(props.value);
        }
      };
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, []);

    useImperativeHandle(ref, () => ({
      getValue() {
        return value;
      },
      afterGuiAttached() {
        // Focus on the input field when the editor is attached
        inputRef.current.focus();
      },
    }));

    const handleChange = (e) => {
      e.stopPropagation();
      if (!isNaN(e.target.value) && e.target.value >= 0) {
        if ((props.type === 'qty' || props.type === 'bundleQty') && e.target.value.indexOf('.') >= 0) return;
        setValue(e.target.value);
      }
    };

    const onKeyDownHandle = (event) => {
      event.stopPropagation();
      if (poId && event.key === 'Enter' && event.target.value != props.value && props.value >= 0) {
        if (props.type === 'qty' || props.type === 'bundleQty') {
          const { node } = props || {};
          const { quantity, bundleQTY } = node.data;
          if (props.type === 'bundleQty' && (quantity % Number(value) !== 0)) {
            notification.error({
              message: "Update Quantity",
              description: 'Quantity should be  divisible on bundle quantity.',
              top: 65
            });
            return;
          } if (props.type === 'qty' && (Number(value) % bundleQTY !== 0)) {
            notification.error({
              message: "Update Quantity",
              description: 'Quantity should be  divisible on bundle quantity.',
              top: 65
            });
            return;
          }
          dispatch(UpdateQuantity({
            id: props.id,
            params: {
              purchase_order: {
                ...(props.type === 'qty' ? { order_quantity: Number(value) } : { bundle_quantity: Number(value) })
              }
            }
          })).then(({ payload }) => {
            if (payload?.success) {
              node.data.quantity = payload?.po?.order_quantity;
              node.data.bundleQTY = payload?.po?.bundle_quantity;
              node.data.profit = payload?.po?.profit;
              dispatch(GetTopStats({ poId }));
              gridApi.redrawRows({ rowNodes: [node] });
            }
          });
          props.setEditQty('');
        } else if (props.type === 'cost') {
          dispatch(UpdateCost({
            id: props.id,
            params: {
              vendor_item: {
                cost: parseFloat(value)
              }
            }
          })).then(({ payload }) => {
            const { node } = props || {};
            node.data.cost = payload?.purchase_order?.cost;
            node.data.profit = payload?.purchase_order?.profit;
            dispatch(GetTopStats({ poId }));
            gridApi.redrawRows({ rowNodes: [node] });
          });
          props.setEditQty('');
        }
      } else if (poId && event.key === 'Enter') props.setEditQty('');
    }

    return <input className="table-input" ref={inputRef} value={value} onChange={handleChange} onKeyDown={onKeyDownHandle} />;
  });

  const UPCRenderer = ({ data }) => {
    return <Tooltip title={data.vendorUPC}><p>{data.vendorUPC || 'N/A'}</p></Tooltip>;
  };

  const NameRenderer = ({ data }) => {
    return (
      <div className="copy-wrapper">
        <img height={34} width={34} src={data.image || '/images/no-image.png'} alt="Product" />
        <Tooltip title={data.name}><p>{data.name || 'N/A'}</p></Tooltip>
      </div>
    );
  };

  const QuantityRenderer = ({ data, node }) => {
    return (
      data?.id !== editQty ? <div className="button-cell">
        {data.quantity && (
          <span>
            {String(data.quantity).length > 5 ? (
              <Tooltip title={data.quantity}>
                <p className="overflow-text-2">{data.quantity}</p>
              </Tooltip>
            ) : (
              <p>{data.quantity}</p>
            )}
          </span>
        )}
        <button onClick={(event) => {
          event.stopPropagation();
          setEditQty(data?.id);
        }} 
        disabled={data?.poData?.feed?.status}
        className="edit-button">{data?.poData?.feed?.status ? <img src={DisabledEditIcon} /> : <img src={EditIcon} />}</button>
      </div> : <CustomEditor setEditQty={setEditQty} value={data?.quantity || 0} id={data?.id} type='qty' node={node} />
    );
  };

  const BundleQTYRenderer = ({ data, node }) => {
    return (
      data?.id !== editBundleQty ? <div className="button-cell">
        {typeof data.bundleQTY !== 'undefined' && (
          <span>
            {String(data.bundleQTY || 0).length > 5 ? (
              <Tooltip title={data.bundleQTY || 0}>
                <p className="overflow-text-2" >{data.bundleQTY || 0}</p>
              </Tooltip>
            ) : (
              <p>{data.bundleQTY || 0}</p>
            )}
          </span>
        )}
        <button onClick={(event) => {
          event.stopPropagation();
          setEditBundleQty(data?.id);
        }} className="edit-button"
        disabled={data?.poData?.feed?.status}
        >{data?.poData?.feed?.status ? <img src={DisabledEditIcon} /> : <img src={EditIcon} />}</button>
      </div> : <CustomEditor setEditQty={setEditBundleQty} value={data?.bundleQTY || 0} id={data?.id} type='bundleQty' node={node} />
    );
  };

  const ASINRenderer = ({ data }) => {
    return <p className="text-primary" onClick={() => window.open(`https://www.amazon.com/dp/${data?.asin}`, '_blank')}>{data.asin || 'N/A'}</p>;
  };

  const MarketplaceRenderer = ({ data }) => {
    return (
        <img src={AmazonSKu || '/images/no-image.png'} alt="Marketplace" />
    );
  };

  const SKURenderer = ({ data }) => {
    const { SKU, matchingCount, vendorAsinId, userId, poData, attachedToDifferentAsin } = data;
    const isDisabled = poData?.feed?.status;
    if (attachedToDifferentAsin) {
      return (
        <Box className="change-sku" gap={0.5} display="flex" flexDirection="column" height="100%" justifyContent="center">
          <Box component="span" display="flex" gap={2} color="#4E5969" lineHeight="14px" fontSize="12px" fontWeight="600" ><span><img className="remove-max-width" src={SkuNotChange} alt="no-sku-found" /></span><Box component="span" whiteSpace="pre-line" textAlign="left"> SKU is attached to another ASIN</Box></Box>
          <Box component="span" color="#1565D8" className="fw-bold cursor-pointer" sx={{ textDecoration: "underline" }} lineHeight="14px" fontSize="12px"
            onClick={() => {
              if (isDisabled) return;
              setChangeSKu(true);
              dispatch(GetOtherSkusForAsin({ vendorasin_id: vendorAsinId, user_id: userId }));
              setData(data);
            }}
          >Change SKU</Box>
        </Box>
      );
    } else if (matchingCount && matchingCount === 1) {
      return (
        <Box display="flex" flexDirection="column" justifyContent="center" height="100%" alignItems="center">
          <Box component="span" color="#4E5969" fontSize="14px" lineHeight="14px">{SKU || 'N/A'}</Box>
          <Box
            component="span"
            color="#1565D8"
            className="fw-bold cursor-pointer"
            sx={{ textDecoration: "underline" }}
            lineHeight="14px"
            fontSize="12px"
            onClick={() => {
              if (isDisabled) return;
              setAddSku(true);
              setData(data);
            }}
          >
            Add New SKU
          </Box>
        </Box>
      );
    } else if (matchingCount) {
      return (
        <Box className="matching-sku" display="flex" flexDirection="column" justifyContent="center" height="100%" alignItems="center">
          <Box component="span" color="#4E5969" fontSize="14px" lineHeight="14px">{SKU || 'N/A'}</Box>
          <Box component="span" color="#00A3FF" lineHeight="14px" fontSize="12px" fontWeight={600}>{matchingCount || 0} Matching SKUs</Box>
          <Box
            component="span"
            className="cursor-pointer fw-bold"
            color="#1565D8"
            sx={{ textDecoration: "underline" }}
            lineHeight="14px"
            fontSize="12px"
            onClick={() => {
              if (isDisabled) return;
              setChangeSKu(true);
              dispatch(GetOtherSkusForAsin({ vendorasin_id: vendorAsinId, user_id: userId }));
              setData(data);
            }}
          >
            Change SKU
          </Box>
        </Box>
      );
    } else if (!SKU || SKU === 'N/A') {
      return (
        <Box className="missing-sku" gap={1} display="flex" flexDirection="column" height="100%" justifyContent="center">
          <Box component="span" mr={2.75} color="#4E5969" lineHeight="14px" fontSize="12px" fontWeight="600">
            <img className="me-2" src={SkuNotFound} alt="no-sku-found" /> No SKU Found
          </Box>
          <Box
            component="span"
            color="#1565D8"
            className="fw-bold cursor-pointer"
            sx={{ textDecoration: "underline" }}
            lineHeight="14px"
            fontSize="12px"
            onClick={() => {
              if (isDisabled) return;
              setAddSku(true);
              setData(data);
            }}
          >
            Add New SKU
          </Box>
        </Box>
      );
    }
  };

  const StatusRenderer = ({ data }) => {
    let statusDisplay = data.status === 'OK' || data.status === 'TO BE CREATED' ? data?.status : 'GATED';
    let statusClass = '';

    switch (statusDisplay) {
      case 'OK':
        statusClass = 'success';
        break;
      case 'TO BE CREATED':
        statusClass = 'warning';
        break;
      case 'GATED':
        statusClass = 'gated';
        break;
      default:
        statusClass = '';
        break;
    }

    return <p className={`badges-wrapper ${statusClass}`}>{statusDisplay}</p>;
  };

  const CostRenderer = ({ data, node }) => {
    return (
      data?.id !== editCost ? <div className="button-cell">
        {typeof data.cost !== 'undefined' && (
          <span>
            {String(`$${data.cost || 0}`).length > 5 ? (
              <Tooltip title={`$${data.cost || 0}`}>
                <p className="sku-text overflow-text">${data.cost || 0}</p>
              </Tooltip>
            ) : (
              <p className="sku-text">${data.cost || 0}</p>
            )}
          </span>
        )}
        <button onClick={(event) => {
          event.stopPropagation();
          setEditCost(data?.id);
        }} className="edit-button"
        disabled={data?.poData?.feed?.status}
        >{data?.poData?.feed?.status ? <img src={DisabledEditIcon} /> : <img src={EditIcon} />}</button>
      </div> : <CustomEditor setEditQty={setEditCost} value={data.cost || 0} id={data?.id} type='cost' node={node} />
    );
  };

  const VendorRenderer = ({ data }) => {
    return (
      <div className="product-data-item">
        <div className="vendor">
          <Tooltip title={data.vendor.vendorName}>
            <p>{data.vendor.vendorName}</p>
          </Tooltip>
          <div className="product-details">
            <Tooltip title={data.vendor.vendorAddress}>
              <h6>{data.vendor.vendorAddress}</h6>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };

  const ProfitRenderer = ({ data }) => {
    return (
      <span>
        {typeof data.profit !== 'undefined' && (
          String(`$${data.profit}`).length > 6 ? (
            <Tooltip title={`$${data.profit}`}>
              <p className="sku-text overflow-text">{`$${data.profit}`}</p>
            </Tooltip>
          ) : (
            <p className="sku-text">{`$${data.profit}`}</p>
          )
        )}
      </span>
    );
  };

  const AvailableQuantitiesRenderer = ({ data }) => {
    return <p className="sku-text">{data.availableQuantities}</p>;
  };

  const onRowSelected = ({ data }) => {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data?.id);
    setSelectedRows({
      ...selectedRows,
      [page_num]: selectedData
    });
  };

  const gridReady = (params) => {
    gridApi = params.api;
  };

  const columnDefs = useMemo(() => [
    {
      headerName: "",
      field: "checkbox",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 20,
      cellRenderer: (params) => {
        const { data, node } = params || {};
        if (flatMap(Object.values(selectedRows))?.includes(data?.id)) node.setSelected(true);
      },
      suppressMenu: true
    },
    {
      headerName: "Name",
      field: "name",
      resizable: true,
      cellRendererFramework: NameRenderer,
      flex: 1,
      minWidth: 200,
      suppressMenu: true,
    },
    {
      headerName: "ASIN",
      field: "ASIN",
      resizable: true,
      minWidth: 135,
      suppressMenu: true,
      flex: 1,
      cellRendererFramework: ASINRenderer,
    },
    {
      headerName: "SKU",
      field: "SKU",
      resizable: true,
      minWidth: 150,
      suppressMenu: true,
      flex: 1,
      cellRendererFramework: SKURenderer,
    },
    {
      headerName: "Marketplace",
      field: "marketplace",
      resizable: true,
      minWidth: 100,
      suppressMenu: true,
      flex: 1,
      cellRendererFramework: MarketplaceRenderer,
    },
    {
      headerName: "Status",
      field: "status",
      resizable: true,
      minWidth: 160,
      suppressMenu: true,
      flex: 1,
      cellRendererFramework: StatusRenderer,
    },
    {
      headerName: "Order Quantity",
      field: "quantity",
      resizable: true,
      minWidth: 130,
      cellEditor: "agTextCellEditor",
      flex: 1,
      suppressMenu: true,
      headerClass: 'center-header',
      cellRendererFramework: QuantityRenderer,
    },
    {
      headerName: "Bundle QTY",
      field: "bundleQTY",
      resizable: true,
      minWidth: 130,
      flex: 1,
      cellEditor: "agTextCellEditor",
      suppressMenu: true,
      cellRendererFramework: BundleQTYRenderer,
    },
    {
      headerName: "Cost",
      field: "cost",
      resizable: true,
      minWidth: 130,
      suppressMenu: true,
      flex: 1,
      cellRendererFramework: CostRenderer,
    },
    {
      headerName: "Profit",
      field: "profit",
      resizable: true,
      minWidth: 130,
      flex: 1,
      suppressMenu: true,
      cellRendererFramework: ProfitRenderer,
    },
    {
      headerName: "Vendor",
      field: "vendor",
      resizable: true,
      minWidth: 150,
      suppressMenu: true,
      flex: 1,
      cellRendererFramework: VendorRenderer,
    },
  ], [
    page_num,
    editQty,
    editBundleQty,
    editCost
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 8,
    autoSizeColumns: true,
    defaultColDef: {
      cellStyle: { border: "1px solid #E6E6E6", textAlign: 'center' },
    },
  };

  const clearStates = () => {
    setChangeSKu(false);
    setSelectedRow(null);
    setInputActive(false);
    setSearch('');
    setSkuValue('');
    setAddSku(false);
  };

  useEffect(() => {
    if (otherSkus) {
      setRows(otherSkus);
      otherSkus.forEach((row) => {
        if (row.sku === skuValue) {
          setSelectedRow(row.id);
          setSkuValue('');
        } else if (row.sku === data?.SKU) {
          setSelectedRow(row.id);
        } else {
          setSkuValue('');
        }
      });
    }
  }, [otherSkus]);

  const handleRadioChange = (id) => {
    setSelectedRow(id);
  };

  const addRow = () => {
    const newRow = { id: null, sku: '', status: 'N/A', vendor: data?.vendor_name };
    setRows([...rows, newRow]);
    setInputActive(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      dispatch(AddNewSku({ id: data?.id, sku: skuValue })).then(({ payload }) => {
        if (payload?.success) {
          notification.success({
            message: 'New SKU Added',
            description: 'New SKU has been added successfully',
            top: 65,
          });
          dispatch(GetOtherSkusForAsin({ vendorasin_id: data?.vendorAsinId, user_id: data?.userId }));
          setInputActive(false);
          setSearch('');
        }
      });
    }
  };

  const handleSearch = debounce((value) => {
    const filteredRows = otherSkus.filter((row) => row.sku?.toLowerCase().includes(value.toLowerCase()));
    setRows(filteredRows);
  }, 300);

  return (
    <>
      <TableWrapper>
        <div className={`ag-theme-material po-table ${className}`} style={{ width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            gridOptions={gridOptions}
            onGridReady={gridReady}
            rowHeight={60}
            pagination={false}
            columnDefs={columnDefs}
            rowData={rowData}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            onSelectionChanged={onRowSelected}
          />
        </div>
        <Pagination
          total={total}
          totalPages={ceil(total / per_page + 1)}
          pageNumber={page_num}
          pageLimit={per_page}
          handleLimitChange={(e) => handlePagination(e, "pageLimit")}
          handlePageNumberChange={(e) => handlePagination(e, "pageNumber")}
          handlePageChangeLeft={(e) => handlePagination(e, "pageNumber")}
          handlePageChangeRight={(e) => handlePagination(e, "pageNumber")}
          refreshGridClick={refreshGridClick}
        />
      </TableWrapper>
      <Modal open={addSku} size="sm">
        <Box fontWeight="700" display="flex" gap={1} color="#000000" alignItems="center">
          <img className="cursor-pointer" onClick={() => {
            clearStates();
          }} src={ArrowBack} alt="no-arrow" />
          <h2 className="mb-0">Add New SKU</h2>
        </Box>
        <Box marginTop="40px" fontSize="18px" color="#4E5969" className="fw-bold">
          Please Enter SKU #
        </Box>
        <Box mt={1.5}>
          <Input placeholder="SKU" marginBottom="24px" className="h-32" value={skuValue} onChange={(e)=>setSkuValue(e.target.value)}/>
          <Button width="100%" text="Confirm" className="w-100 fw-bold h-32" 
          disabled={!skuValue}
          onClick={()=>{
            dispatch(AddNewSku({id: data?.id, sku: skuValue})).then(({payload})=>{
              if(payload?.success){
                notification.success({
                  message: "New SKU Added",
                  description: 'New SKU has been added successfully',
                  top: 65
                });
                setAddSku(false);
                setSkuValue('');
                const { pagination, filter } = filters || {}
                dispatch(GetGraftPo({ poId, per_page: pagination?.per_page, page: pagination?.page_num, filters: filter }));
              }
            })
          }}/>
        </Box>
      </Modal>

      <Modal open={changeSku}>
        <Box fontWeight="700" display="flex" gap={1} color="#000000" alignItems="center">
          <img onClick={() => {
            clearStates();
            const { pagination, filter } = filters || {}
            dispatch(GetGraftPo({ poId, per_page: pagination?.per_page, page: pagination?.page_num, filters: filter }));
            }} className="cursor-pointer" src={ArrowBack} alt="no-arrow" />
          <h2 className="mb-0"> Change attached SKU</h2>
        </Box>
        <Box className="d-flex" my={5} gap={2} alignItems="center">
          <img width="72px" height="72px" src={data?.image || Product} alt="no-product" />
          <Box className="d-flex flex-column" gap={1}>
            <Box color="#1565D8" fontSize="18px" className="fw-bold">
              {data?.name}
            </Box>
            <Box color="#4E5969" fontSize="12px" display="flex" gap={0.5}>
              <Box className="fw-bold">ASIN:</Box> {data?.asin}
            </Box>
          </Box>
        </Box>
        <Box border="1px solid #E6E6E6" borderRadius="4px">
          <SearchInput className="height-change" placeholder="Search by SKU" value={search} onChange={({ target }) => {
            setSearch(target?.value);
            handleSearch(target?.value);
            }}/>
          <Spin spinning={loading}>
          <ModalTableWrapper>
            <TableContainer sx={{ maxHeight: 320, overflow: "auto" }} >
              <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>SKU</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Vendor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {!isEmpty(rows) ? (
                      rows.map((row) => (
                        <TableRow key={row.id} className={selectedRow === row.id ? 'selected' : ''}>
                          <TableCell>
                            {row.id ? (
                              <>
                                <Radio
                                  checked={selectedRow === row.id}
                                  onChange={() => handleRadioChange(row.id)}
                                  value={row.id}
                                />
                                <Box component="span" color="#1565D8" fontSize="14px" className="fw-bold">
                                  {row.sku || 'N/A'}
                                </Box>
                              </>
                            ) : (
                              <Input
                                width="100px"
                                marginBottom="0px"
                                className="h-32"
                                placeholder="Sku"
                                value={skuValue}
                                onChange={(e) => setSkuValue(e.target.value)}
                                onKeyDown={handleKeyDown}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <Box
                              className="text-ellipse"
                              borderRadius="4px"
                              bgcolor="#009C340D"
                              color="#009C34"
                              border="1px solid #009C34"
                              padding="0 8px"
                            >
                              {row.sku_status === 'Active' ? 'Active' : 'Inactive'}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box color="#4E5969" fontSize="10px">
                              {row.vendor_name || 'N/A'}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          No SKU Found
                        </TableCell>
                      </TableRow>
                    )}

                </TableBody>

              </Table>

            </TableContainer>
            <Box margin="0 16px" my={1.5}>
              <Button width="100%" text="Add New Sku" endIcon={<img src={AddIcon} alt="no-icon" />} className="outlined w-100 fw-bold" variant="outlined" color="primary" onClick={addRow} disabled={inputActive}/>
            </Box>

          </ModalTableWrapper>
          </Spin>
        </Box>
        <Box my={1.5}>
          <Button width="100%" text="Confirm" className="w-100 fw-bold h-48" disabled={!selectedRow} 
           onClick={()=>{
            dispatch(ConfirmChangedSku({vendorasin_id: data?.vendorAsinId, seller_sku_id: selectedRow, po_id: data?.id})).then(({payload})=>{
              if(payload?.success){
                notification.success({
                  message: "SKU Changed",
                  description: 'SKU has been changed successfully',
                  top: 65
                });
                clearStates();
                const { pagination, filter } = filters || {}
                dispatch(GetGraftPo({ poId, per_page: pagination?.per_page, page: pagination?.page_num, filters: filter }));
              }
            })
          }}/>
        </Box>
      </Modal>
    </>
  );
};

export default POTable;
