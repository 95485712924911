import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleRejectedResponse } from './utils';
import axios from '../../axios_config';
import { notification } from 'antd';

export const GetLinkForInformedCo = createAsyncThunk(
  '/users/link_for_informed_co',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        '/api/v2/users/link_for_informed_co'
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Informed Co Link', rejectWithValue);
    }
  }
);

export const AffiliateManagerInviteAccept = createAsyncThunk(
  '/api/v2/affiliates/:id/accept_invite',
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/api/v2/affiliates/${id}/accept_invite`
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Accept Affiliate Invite', rejectWithValue);
    }
  }
);

export const GetLinkForBQoolRepricer = createAsyncThunk(
  '/users/link_for_bqool_repricer',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        '/api/v2/users/link_for_bqool_repricer'
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get BQool Repricer Link', rejectWithValue);
    }
  }
);

export const GenerateLinkForBQoolFile = createAsyncThunk(
  '/users/generate_link_for_bqool',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v2/users/generate_link_for_bqool?additional_cost=${data}`
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get BQool Repricer Link', rejectWithValue);
    }
  }
);

export const UpdatePassword = createAsyncThunk(
  '/users/:id/update_password',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { id } = getState()?.auth?.currentUser;
      const response = await axios.patch(
        `/api/v2/users/${id}/update_password`,
        {
          user: {
            ...data
          }
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Password', rejectWithValue);
    }
  }
);

export const UpdateProfile = createAsyncThunk(
  '/users/:id',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { id } = getState()?.auth?.currentUser;
      const response = await axios.patch(
        `/api/v2/users/${id}`,
        {
          user: data
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Profile', rejectWithValue);
    }
  }
);

export const GetUserCompany = createAsyncThunk(
  '/companies/my_company',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        '/api/v2/companies/my_company'
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get User Company', rejectWithValue);
    }
  }
);

export const GetCompanyAddresses = createAsyncThunk(
  '/my_company_tab_addresses',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v2/addresses/my_company_tab_addresses`
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Company Addresses', rejectWithValue);
    }
  }
);

export const MarkAddressAsDefault = createAsyncThunk(
  '/addresses/:id/set_default',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/api/v2/addresses/${data}/set_default`
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Mark Address As Default', rejectWithValue);
    }
  }
);

export const DeleteAddress = createAsyncThunk(
  '/companies/:id',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `/api/v2/addresses/${data}`
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Delete Address', rejectWithValue);
    }
  }
);

export const CreateCompanyAddress = createAsyncThunk(
  '/addresses/create_company_address',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { id } = getState()?.account?.companyHash?.company;
      const company_id = id || data?.company_id;
      if (data?.company_id) delete data.company_id;

      const response = await axios.post(
        `/api/v2/addresses/create_company_address?id=${company_id}`,
        null,
        {
          params: {
            address: data
          }
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Create Company Address', rejectWithValue);
    }
  }
);

export const UpdateCompanyAddress = createAsyncThunk(
  '/addresses/:id',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { id, address } = data;
      const response = await axios.patch(
        `/api/v2/addresses/${id}`,
        null,
        {
          params: {
            address
          }
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Edit Company Address', rejectWithValue);
    }
  }
);

export const SaveCompanyDetails = createAsyncThunk(
  '/companies/:id',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { id } = getState()?.account?.companyHash?.company;
      const response = await axios.patch(
        `/api/v2/companies/${id}`,
        {
          company: data
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Save Company Details', rejectWithValue);
    }
  }
);

export const GetAllStores = createAsyncThunk(
  '/stores/list_all_stores',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        '/api/v2/stores/list_all_stores'
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get All Stores', rejectWithValue);
    }
  }
);

export const DeleteStore = createAsyncThunk(
  '/stores/:id/delete_store',
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `/api/v2/stores/${id}/delete_store`
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Delete Store', rejectWithValue);
    }
  }
);

const account = createSlice({
  name: 'account',
  initialState: {
    informedCoHash: {
      informedCoLink: '',
      fetchingInformedCoLink: false
    },
    bQoolRepricerHash: {
      bQoolRepricerLink: '',
      fetchingBQoolRepricerLink: false,
      fetchingBQoolFileLink: false
    },
    storesHash: {
      stores: [],
      fetchingStores: false,
      deletingStore: false,
      fetchingMarketPlaces: false
    },
    companyHash: {
      company: {},
      fetchingCompany: false,
      addresses: [],
      fetchingCompanyAddresses: false,
      markingAddressAsDefault: false,
      deletingCompanyAddress: false,
      creatingCompanyAddress: false,
      savingCompanyDetails: false,
      updatingCompanyAddress: false
    },
    updatingPassword: false,
    updatingProfile: false
  },
  extraReducers: {
    [GetLinkForInformedCo.pending]: (state, action) => ({
      ...state,
      informedCoHash: {
        ...state.informedCoHash,
        fetchingInformedCoLink: true
      }
    }),
    [GetLinkForInformedCo.fulfilled]: (state, action) => {
      const { repricing_file_path } = action?.payload?.data || {};
      return {
        ...state,
        informedCoHash: {
          ...state.informedCoHash,
          fetchingInformedCoLink: false,
          informedCoLink: repricing_file_path
        }
      }
    },
    [GetLinkForInformedCo.rejected]: (state, action) => ({
      ...state,
      informedCoHash: {
        ...state.informedCoHash,
        fetchingInformedCoLink: false
      }
    }),
    [GetLinkForBQoolRepricer.pending]: (state, action) => ({
      ...state,
      bQoolRepricerHash: {
        ...state.bQoolRepricerHash,
        fetchingBQoolRepricerLink: true
      }
    }),
    [GetLinkForBQoolRepricer.fulfilled]: (state, action) => {
      const { link_for_BQool_repricer } = action?.payload?.data || {};
      return {
        ...state,
        bQoolRepricerHash: {
          ...state.bQoolRepricerHash,
          fetchingBQoolRepricerLink: false,
          bQoolRepricerLink: link_for_BQool_repricer
        }
      }
    },
    [GetLinkForBQoolRepricer.rejected]: (state, action) => ({
      ...state,
      bQoolRepricerHash: {
        ...state.bQoolRepricerHash,
        fetchingBQoolRepricerLink: false
      }
    }),
    [GenerateLinkForBQoolFile.pending]: (state, action) => ({
      ...state,
      bQoolRepricerHash: {
        ...state.bQoolRepricerHash,
        fetchingBQoolFileLink: true
      }
    }),
    [GenerateLinkForBQoolFile.fulfilled]: (state, action) => {
      return {
        ...state,
        bQoolRepricerHash: {
          ...state.bQoolRepricerHash,
          fetchingBQoolFileLink: false
        }
      }
    },
    [GenerateLinkForBQoolFile.rejected]: (state, action) => ({
      ...state,
      bQoolRepricerHash: {
        ...state.bQoolRepricerHash,
        fetchingBQoolFileLink: false
      }
    }),
    [UpdatePassword.pending]: (state, action) => ({
      ...state,
      updatingPassword: true
    }),
    [UpdatePassword.fulfilled]: (state, action) => {
      const { message } = action?.payload?.data || {};

      notification.success({
        message,
        top: 65,
      })

      return {
        ...state,
        updatingPassword: false
      }
    },
    [UpdatePassword.rejected]: (state, action) => ({
      ...state,
      updatingPassword: false
    }),
    [UpdateProfile.pending]: (state, action) => ({
      ...state,
      updatingProfile: true
    }),
    [UpdateProfile.fulfilled]: (state, action) => {
      const { message } = action?.payload?.data || {};

      notification.success({
        message,
        top: 65,
      })

      return {
        ...state,
        updatingProfile: false
      }
    },
    [UpdateProfile.rejected]: (state, action) => ({
      ...state,
      updatingProfile: false
    }),
    [GetUserCompany.pending]: (state, action) => ({
      ...state,
      companyHash: {
        ...state.companyHash,
        fetchingCompany: true
      }
    }),
    [GetUserCompany.fulfilled]: (state, action) => {
      const { company } = action?.payload?.data || {};

      return {
        ...state,
        companyHash: {
          ...state.companyHash,
          fetchingCompany: false,
          company
        }
      }
    },
    [GetUserCompany.rejected]: (state, action) => ({
      ...state,
      companyHash: {
        ...state.companyHash,
        fetchingCompany: false
      }
    }),
    [GetCompanyAddresses.pending]: (state, action) => ({
      ...state,
      companyHash: {
        ...state.companyHash,
        fetchingCompanyAddresses: true
      }
    }),
    [GetCompanyAddresses.fulfilled]: (state, action) => {
      const { addresses } = action?.payload?.data || {};

      return {
        ...state,
        companyHash: {
          ...state.companyHash,
          fetchingCompanyAddresses: false,
          addresses
        }
      }
    },
    [GetCompanyAddresses.rejected]: (state, action) => ({
      ...state,
      companyHash: {
        ...state.companyHash,
        fetchingCompanyAddresses: false
      }
    }),
    [MarkAddressAsDefault.pending]: (state, action) => ({
      ...state,
      companyHash: {
        ...state.companyHash,
        markingAddressAsDefault: true
      }
    }),
    [MarkAddressAsDefault.fulfilled]: (state, action) => {
      const { message } = action?.payload?.data || {};

      notification.success({
        message,
        top: 65,
      })

      return {
        ...state,
        companyHash: {
          ...state.companyHash,
          markingAddressAsDefault: false
        }
      }
    },
    [MarkAddressAsDefault.rejected]: (state, action) => ({
      ...state,
      companyHash: {
        ...state.companyHash,
        markingAddressAsDefault: false
      }
    }),
    [DeleteAddress.pending]: (state, action) => ({
      ...state,
      companyHash: {
        ...state.companyHash,
        deletingCompanyAddress: true
      }
    }),
    [DeleteAddress.fulfilled]: (state, action) => {
      const { message } = action?.payload?.data || {};

      notification.success({
        message,
        top: 65,
      })

      return {
        ...state,
        companyHash: {
          ...state.companyHash,
          deletingCompanyAddress: false
        }
      }
    },
    [DeleteAddress.rejected]: (state, action) => ({
      ...state,
      companyHash: {
        ...state.companyHash,
        deletingCompanyAddress: false
      }
    }),
    [CreateCompanyAddress.pending]: (state, action) => ({
      ...state,
      companyHash: {
        ...state.companyHash,
        creatingCompanyAddress: true
      }
    }),
    [CreateCompanyAddress.fulfilled]: (state, action) => {
      const { message, messages, status } = action?.payload?.data || {};

      if (status == 200) {
        notification.success({
          message,
          top: 65,
        });
      } else {
        let description = message;
        if (messages?.length) {
          description = messages.toString();
        }
        notification.info({
          message: 'Create Company Address',
          description,
          top: 65,
        });
      }

      return {
        ...state,
        companyHash: {
          ...state.companyHash,
          creatingCompanyAddress: false
        }
      }
    },
    [CreateCompanyAddress.rejected]: (state, action) => ({
      ...state,
      companyHash: {
        ...state.companyHash,
        creatingCompanyAddress: false
      }
    }),
    [UpdateCompanyAddress.pending]: (state, action) => ({
      ...state,
      companyHash: {
        ...state.companyHash,
        updatingCompanyAddress: true
      }
    }),
    [UpdateCompanyAddress.fulfilled]: (state, action) => {
      const { message, messages, status } = action?.payload?.data || {};

      if (status == 200) {
        notification.success({
          message,
          top: 65,
        });
      } else {
        let description = message;
        if (messages?.length) {
          description = messages.toString();
        }
        notification.success({
          message: 'Update Company Address',
          description,
          top: 65,
        });
      }

      return {
        ...state,
        companyHash: {
          ...state.companyHash,
          updatingCompanyAddress: false
        }
      }
    },
    [UpdateCompanyAddress.rejected]: (state, action) => ({
      ...state,
      companyHash: {
        ...state.companyHash,
        updatingCompanyAddress: false
      }
    }),
    [SaveCompanyDetails.pending]: (state, action) => ({
      ...state,
      companyHash: {
        ...state.companyHash,
        savingCompanyDetails: true
      }
    }),
    [SaveCompanyDetails.fulfilled]: (state, action) => {
      const { message } = action?.payload?.data || {};

      notification.success({
        message: message || 'Company Details Updated Successfully',
        top: 65,
      });

      return {
        ...state,
        companyHash: {
          ...state.companyHash,
          savingCompanyDetails: false
        }
      }
    },
    [SaveCompanyDetails.rejected]: (state, action) => ({
      ...state,
      companyHash: {
        ...state.companyHash,
        savingCompanyDetails: false
      }
    }),
    [GetAllStores.pending]: (state, action) => ({
      ...state,
      storesHash: {
        ...state.storesHash,
        fetchingStores: true
      }
    }),
    [GetAllStores.fulfilled]: (state, action) => {
      const { stores } = action?.payload?.data || {};

      return {
        ...state,
        storesHash: {
          ...state.storesHash,
          fetchingStores: false,
          stores
        }
      }
    },
    [GetAllStores.rejected]: (state, action) => ({
      ...state,
      storesHash: {
        ...state.storesHash,
        fetchingStores: false
      }
    }),
    [DeleteStore.pending]: (state, action) => ({
      ...state,
      storesHash: {
        ...state.storesHash,
        deletingStore: true
      }
    }),
    [DeleteStore.fulfilled]: (state, action) => {
      const { message } = action?.payload?.data || {};

      notification.success({
        message,
        top: 65,
      })

      return {
        ...state,
        storesHash: {
          ...state.storesHash,
          deletingStore: false
        }
      }
    },
    [DeleteStore.rejected]: (state, action) => ({
      ...state,
      storesHash: {
        ...state.storesHash,
        deletingStore: false
      }
    }),
  }
})

const { reducer, actions } = account;
export default reducer;
