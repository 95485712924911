import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleRejectedResponse } from '../utils';
import { fetch as axios } from '../../../axios_config';
import { filter, findIndex, isEmpty } from 'lodash';
import { notification } from 'antd';

export const GetAllShipmentSKUs = createAsyncThunk(
  '/list-shipment-items',
  async ({ user_id, filters, page }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/list-shipment-items/${user_id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: { filters, page }
        },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get All Shipment SKUs', rejectWithValue);
    }
  }
);

export const GetUserShipmentDetails = createAsyncThunk(
  '/user-items-detail',
  async (user_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/user-items-detail/${user_id}`,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get User Shipment Details', rejectWithValue);
    }
  }
);

export const CreateShipmentPlan = createAsyncThunk(
  '/create-shipment-plan',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/create-shipment-plan`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Create Shipment Plan', rejectWithValue);
    }
  }
);

export const CreateShipment = createAsyncThunk(
  '/create-shipment',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/create-shipment`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Create Shipment', rejectWithValue);
    }
  }
);

export const createShipmentInitialState = {
  shipmentSKUs: [],
  fetchingShipmentSKUs: false,
  userShipmentDetails: {},
  fetchingUserShipmentDetails: false,
  creatingShipmentPlan: false,
  gatedProducts: [],
  shipmentPlanDetails: {},
  erroredPendingPos: [],
  creatingShipment: false,
  totalSkuCount: 0,
  shipments: [],
  workingShipments: 0
};

export const createShipmentReducers = {
  [GetAllShipmentSKUs.pending]: (state, action) => ({
    ...state,
    createShipment: {
      ...state.createShipment,
      fetchingShipmentSKUs: true,
      shipmentSKUs: []
    }
  }),
  [GetAllShipmentSKUs.fulfilled]: (state, action) => {
    const { result, count } = action?.payload?.data || {};

    return {
      ...state,
      createShipment: {
        ...state.createShipment,
        fetchingShipmentSKUs: false,
        shipmentSKUs: result,
        totalSkuCount: count
      }
    }
  },
  [GetAllShipmentSKUs.rejected]: (state, action) => {
    return {
      ...state,
      createShipment: {
        ...state.createShipment,
        fetchingShipmentSKUs: false,
      }
    }
  },
  [GetUserShipmentDetails.pending]: (state, action) => ({
    ...state,
    createShipment: {
      ...state.createShipment,
      gatedProducts: [],
      fetchingUserShipmentDetails: true,
      userShipmentDetails: {},
    }
  }),
  [GetUserShipmentDetails.fulfilled]: (state, action) => {
    const {
      pendingPoIndicesCount, pendingPosCount, pendingPosUnitsCount, user,
      pendingPoCodes, pendingPoIndices, pendingPosBundlesCount
    } = action?.payload?.data || {};

    return {
      ...state,
      createShipment: {
        ...state.createShipment,
        fetchingUserShipmentDetails: false,
        userShipmentDetails: {
          pendingPoIndicesCount, pendingPosCount, pendingPosUnitsCount, user, pendingPoCodes,
          pendingPoIndices: pendingPoIndices.map((obj) => ({ ...obj, action: '' })),
          pendingPosBundlesCount
        }
      }
    }
  },
  [GetUserShipmentDetails.rejected]: (state, action) => {
    return {
      ...state,
      createShipment: {
        ...state.createShipment,
        fetchingUserShipmentDetails: false,
      }
    }
  },
  [CreateShipmentPlan.pending]: (state, action) => ({
    ...state,
    createShipment: {
      ...state.createShipment,
      creatingShipmentPlan: true,
      shipmentPlanDetails: {}
    }
  }),
  [CreateShipmentPlan.fulfilled]: (state, action) => {
    const { poItems, result, gatedItems } = action?.payload?.data || {};

    const poItemIds = poItems?.map(({ id }) => id);

    const erroredPendingPos = ((typeof result) === 'string') ?
      filter(poItems, (item) => {
        const { vendoritem: { seller_sku: { sku_status } = {} } = {} } = item || {};
        return (sku_status.toLowerCase() !== 'ok');
      }) || [] : [];

    const mappedErroredPendingPos = erroredPendingPos?.map((data) => {
      const { vendoritem, id, checked_in_quantity, bundle_quantity, pending_po_index, storaged_quantity } = data || {};
      const { seller_sku } = vendoritem || {};
      const { vendorasin, sku, sku_status } = seller_sku || {};
      const { name, small_image = '/images/no-image.png' } = vendorasin || {};
      const { po_code } = pending_po_index || {};

      return {
        id,
        checked_in_quantity,
        bundle_quantity,
        sku,
        sku_status,
        name,
        small_image,
        po_code,
        storaged_quantity
      };
    }) || [];

    return {
      ...state,
      createShipment: {
        ...state.createShipment,
        creatingShipmentPlan: false,
        gatedProducts: gatedItems,
        shipmentPlanDetails: {
          poItems,
          result,
          poItemIds,
          erroredPendingPos: mappedErroredPendingPos
        }
      }
    }
  },
  [CreateShipmentPlan.rejected]: (state, action) => {
    return {
      ...state,
      createShipment: {
        ...state.createShipment,
        creatingShipmentPlan: false,
      }
    }
  },
  [CreateShipment.pending]: (state, action) => ({
    ...state,
    createShipment: {
      ...state.createShipment,
      creatingShipment: true,
    }
  }),
  [CreateShipment.fulfilled]: (state, action) => {
    const { message, success, batch, result, workingShipments } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Create Shipment',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      createShipment: {
        ...state.createShipment,
        creatingShipment: false,
        batch,
        shipments: result,
        workingShipments
      }
    }
  },
  [CreateShipment.rejected]: (state, action) => {
    return {
      ...state,
      createShipment: {
        ...state.createShipment,
        creatingShipment: false,
      }
    }
  },
};
